.set_leadss {
	padding-left: 0px;
}
.newcomer {
	padding-left: 10px;
}
.leader_set td p {
	color: var(--color-tertiary-grey-mid, #787D8C);
	font-size: 12px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	font-family: 'Oxygen', sans-serif !important;
	margin-top: 5px;
	margin-bottom: 0px !important;
}
.leader_set .author-tags {
	font-size: 12px !important;
}
.leader_set .font_set {
	font-size: 14px !important;
}
.dropdown-content p {
	border-radius: 8px;
	background: var(--color-secondary-dark-mid, #3C4150);
	padding: 8px 16px;
	color: #FFF !important;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
}
.select__control {
	width: 100% !important;
}
/* .dropdown-content::before {
		content: '';
		position: absolute;
		top: 0px;
		left: 7px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #4a4a4a;
	}
	.dropdown-content {
		position: absolute;
	} */
td.font_set.shements {
	position: relative;
}
.dropdown-content {
	left: -50px;
	z-index: 9999;
}
.leads_expand img {
	width: 20px;
	margin-top: 3px;
}

.leader_set td{ vertical-align: top !important;}


@keyframes arrowDown {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(2px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes arrowUp {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-2px);
	}
	100% {
		transform: translateY(0);
	}
}
.farea_leads th {
	background: var(--color-secondary-dark-light, #505564);
	color: var(--color-white-pure, #FFF);
	padding: 15px 20px;
	font-size: 14px;
	font-weight: 700;
	line-height: 150%;
}
.farea_leads table td {
	border-bottom: none;
}
.farea_leads table td {
	padding: 15px 20px;
	/* vertical-align: middle; */
	color: var(--color-secondary-dark-mid, #3C4150);
	font-size: 15.5px;
	font-weight: 400;
	line-height: 150%;
}
.link_sett {
	color: var(--color-tertiary-grey, #646978);
	font-size: 16px;
	font-weight: 600;
	line-height: 150%;
	/* padding-left: 12px; */
}
.bholss {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
	left: 32em;
	position: relative;
	top: 2em;
}
@media (min-width:1280px) and (max-width:1400px) {
	.bholss {
		left: 23em;
	}
}
@media (min-width:768px) and (max-width:1279px) {
	.bholss {
		left: 22em;
	}
}
@media (min-width:1025px) and (max-width:1279px) {
	.usera {
		width: 33% !important;
	}
}
@media (min-width:320px) and (max-width:767px) {
	.previous,
	.next {
		display: none;
	}
	.leader_only {}
	.leader_only .search_bloxk {
		margin-left: 20px;
	}
	.prockere {
		width: 153px;
	}
	.all_pagination li {
		padding: 2px 0px;
	}
	.tset {
		flex-wrap: wrap;
	}
}
@media (min-width:320px) and (max-width:767px) {
	.previous,
	.next {
		display: none;
	}
}
@media (min-width:320px) and (max-width:1278px) {
	.leader_only {}
	.leader_only .search_bloxk {
		margin-left: 20px;
	}
	.prockere {
		width: 175px;
	}
	.all_pagination li {
		padding: 2px 0px;
	}
	.tset {
		flex-wrap: wrap;
	}
}