.margin_headings {
	color: var(--Color-Secondary-Dark-light, #505564);
	font-family: Roboto;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.margin_headings span {
	color: var(--Color-Secondary-Dark-light, #505564);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.margins_blocks {
    padding: 19px 10px;
}

.set_payments {
	padding: 25px !important;
}

.set_payments h2 {
	margin-bottom: 65px;
}

.paymnent_texting {
	background: url(../../assets/pay-icon.svg) no-repeat;
	background-position: 0px 4px;
	padding-left: 32px;
	margin-top: 30px;
	color: var(--Color-Tertiary-Grey-mid, #787D8C);
	font-family: Oxygen;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 24px */
}
.paymnent_texting.urlChange{
	background: url(../../assets/set-up-pay.7364495.svg) no-repeat;
}

.pricing_pay {
	color: var(--Color-Tertiary-Grey-mid, #787D8C);
	font-family: Oxygen;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	margin-left: 4px;
	margin-right: 0px;
}

.bdr_center {
	border-bottom: 1px solid #B4B9C8;
	margin-top: 45px;
	margin-bottom: 49px;
}

.bdr_center.marginBott {
    
    margin-top: 0;
   
}

/* react-slider */

.horizontal-slider {
	width: 100%;
	height: 5px;
	background: var(--color-white-blue, #F0F5FF);
	border-radius: 10px;
	margin-top: -3px;
}

.thumb {
	height: 15px;
	width: 15px;
	text-align: center;
	background-color: #00aaff;
	color: #00aaff;
	border-radius: 50%;
	cursor: grab;
	top: -5px;
}

.track {
	background: transparent;
}

.horizontal-slider .track-0 {
	background: #00aaff;
	height: 5px;
	border-radius: 100px;
}

.horizontal-slider.test .track-0{
	background: #80c342!important;
}

.horizontal-slider .react-slider-track-1 {
	background: var(--color-white-blue, #F0F5FF);
}

.thumb:focus-visible {
	outline: #00aaff auto 1px;
}

.focusChange:focus-visible {
	outline: #80c342 auto 1px!important;
}


.payment_slider {
	display: flex;
	margin-top: 10px;
}

.saver_btn {
	color: var(--Color-White-Pure, #FFF);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	border-radius: 4px;
	border: 1px solid var(--Color-Primary-Blue, #22A9E0);
	background: var(--Color-Primary-Blue, #22A9E0);
	box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50); cursor: pointer;
	padding: 14px 60px;
}

.left_slader .form-control {
	height: 46px;
	border-radius: 5px;
	background: var(--Color-White-Pure, #FFF);
	box-shadow: 0px 0px 20px 0px rgba(231, 231, 231, 0.25);
	border: 1px solid #f3f3f3;
	border-radius: 0px;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding-left: 35px;
}

.left_slader {
	width: 50%;
	position: relative;
}
.left_slader.maxWidth{
	width:67%
  }
  

.icon_savees {
	position: absolute;
	top: 10px;
	left: 10px;
}

.delete-modal-actions .primary {
	background: #22A9E0 !important;
	border: none;
}

.modal_deklete {
	cursor: pointer;
}


.left_slader .form-control {
    
    margin-left: 0px;
}


@media (min-width:320px) and (max-width:767px) {
	.mutual_blocks h2 {
		font-size: 23px !important;
	}

	.payment_slider {
		
		flex-wrap: wrap;
	}
	.left_slader {
		width: 100%;
		
	}
	.right_slade {
		margin-top: 20px;
	}
	.paymnent_texting {
		
		font-size: 14px;
		
	}


}

.right_slade {
    margin-left: 15px;
}