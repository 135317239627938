.user-profile {
  width: 300px;
  background: var(--Color-White-Off, #f7f8f9);
  overflow: hidden;
  position: fixed;
  right: -300px;
  top: 0;
  z-index: 99999999;
  height: 100%;
  transition: right 0.3s;
  will-change: right;
}
.user-profile.open {
  right: 0;
}
.close-button {
  cursor: pointer;
}

.manage-leads-button {
  background-color: #3c4150;
  color: #f7f8f9;
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  width: 124px;
  line-height: 21px;
  font-weight: 400;
  border: 0.5px solid #505564;
}

.profile-header {
  background: var(--Color-Tertiary-Grey, #646978);
  text-align: center;
  padding: 20px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
}

.profile-image-container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  background: #cccccc;
  margin-right: auto !important;
}

.profile-image {
  width: 100%;
  height: auto;
}

.user-name {
  margin: 10px 0;
  color: var(--Color-White-Pure, #fff);

  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user-id {
  color: var(--Color-White-Blue, #f0f5ff);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: -8px;
}

.user-company {
  color: var(--Color-White-Blue, #f0f5ff);
  margin-top: 5%;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.profile-body {
  background: #f8f8f8;
  padding: 20px;
}

.profile-link {
  display: block;
  margin: 10px 0;
  color: var(--Color-Secondary-Dark-Mid, #3c4150);

  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  margin-top: 20px;
}

.profile-link span {
  margin-right: 15px;
}

.sign-out-button {
  width: calc(100% - 40px);
  margin: 20px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background: var(--Color-Primary-Blue, #22a9e0);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  color: white;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.user_sidebar {
  position: relative;
}

.user_sidebar {
  cursor: pointer;
}
.company_names {
  font-size: 14px;
  text-transform: capitalize;
}
