/* post-page */
.blog_add {
  text-align: right;
  margin-top: -100px;
  padding: 25px;
}

.posting_area {
  margin-top: 15px;
}

.tab-color {
  color: #22a9e0;
}

.tab-section {
  margin-left: 20px;
  cursor: pointer;
}

.stable_view {
}

.posting_area table th {
  background: var(--color-secondary-dark-light, #505564);
  color: var(--color-white-pure, #fff);
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
}

.posting_area table td {
  padding: 15px 20px;
  vertical-align: middle;
  color: var(--color-secondary-dark-mid, #3c4150);
  font-size: 15.5px;
  font-weight: 400;
  line-height: 150%;
}

.post_page_only .mutual_blocks {
  padding: 0px !important;
}

.author-tags {
  color: var(--color-primary-blue, #22a9e0) !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 150%;
}

.font_set {
  font-size: 12px !important;
  color: var(--color-tertiary-grey-mid, #787d8c) !important;
}

.commnts img {
  position: relative;
  margin-right: 7px;
  top: -1px;
}

.dropper .dropdown-toggle::after {
  display: none !important;
}

.dropper .dropdown-menu.show {
  display: block;
  left: -29px;
  background: var(--color-white-off, #f7f8f9);
  box-shadow: 0px 0px 20px 0px rgba(72, 72, 72, 0.2);
  border: none;
  width: 81px;
  height: 104px;
  min-width: 70px;
}

.dropper a {
  font-size: 14px !important;
  padding: 0px;
}

.dropper button {
  font-size: 14px !important;
  padding: 0px;
}

.dropper .dropdown-item {
  line-height: 32px;
  padding: 0px 10px;
}

.dropper .dropdown-item:hover {
  background: #efefef;
}

.posting_area table td {
  border-bottom: none;
}

.posting_area table tr:nth-child(even) {
  background: var(--color-white-off, #f7f8f9);
}

.posting_area table tr:nth-child(odd) {
  background: #efefef;
}

.post_page_only h2 {
  padding: 25px 25px;
}

.padding {
  padding: 0px 25px;
}

.link_sett {
  color: var(--color-tertiary-grey, #646978);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.link_sett a {
  color: var(--color-tertiary-grey, #646978) !important;
  margin-left: 17px !important;
}

.link_sett a span {
  color: var(--color-primary-blue, #22a9e0) !important;
}

.btn_block button {
  border-radius: 4px;
  border: 1px solid var(--color-primary-blue, #22a9e0);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 0px 35px;
  color: var(--color-primary-blue, #22a9e0);
  height: 41px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  background: #fff;
}

.blog_stag {
  margin-top: -74px !important;
  margin-bottom: 35px;
  margin-right: -12px !important;
}

.row.mb-3.curlter.pulter {
  margin-top: -51px;
}

.col-md-6.col-sm-12.d-flex.justify-content-start.align-items-center.link_sett.paltu.jeter.swagger {
  margin-bottom: -48px !important;
  padding-left: 0px !important;
}

.col-md-12.col-sm-12.d-flex.justify-content-end.mku {
  width: 70%;
  justify-content: right;
  margin-left: 30%;
}

.post-data {
  padding: 0px 20px;
  padding-bottom: 20px;
}

.single_posts p {
  padding-left: 13px !important;
}

.posting_area .table {
  --bs-table-bg: none !important;
}

.blog_add {
  margin-top: -70px !important;
}

.left_tags {
  margin-top: 10px !important;
}

.padd_app {
  margin-top: -21px !important;
  margin-left: -8px;
}

.search_bloxk .form-control {
  width: 260px;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid var(--color-white-blue, #f0f5ff);
  height: 41px;
  font-size: 14px;
  padding-right: 13px;
}

.search_bloxk {
  margin-right: -20px;
}

.search_area {
  margin-right: 35px;
}

/* post-page */
/* dropdown-css */
.selected_option {
  background: url(../../assets/s3.svg) no-repeat;
  background-position: 169px 13px !important;
  border-radius: 5px;
  border: 1px solid var(--color-tertiary-grey-light, #b4b9c8);
  padding: 10px 12px;
}

.agent_dropdown {
}

.drop_selection p {
  color: var(--color-tertiary-grey, #646978);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.drop_selection {
  padding: 10px 12px;
}

.selected_option {
  background-repeat: no-repeat;
  background-position: right;
  padding: 10px 12px;
  border: 1px solid #b4b9c8;
  cursor: pointer;
}

.drop_data {
  position: relative;
  min-width: 220px;
}

.drop_data.open .selected_option {
  border: none;
}

.mutual_blocks.amp-pages.manage_tags_set {
  padding-bottom: 30px !important;
}

.agent_dropdown {
}

.drop_selection p {
  color: #646978;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.drop_selection {
  position: absolute;
  width: 100%;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px 12;
  top: 100%;
  left: 0;
  border-right: 1px solid #b4b9c8;
  border-bottom: 1px solid #b4b9c8;
  border-left: 1px solid #b4b9c8;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.drop_data.open {
  border-radius: 5px;
  background: transparent;
  border: none;
}

.selected_option {
  background: url(../../assets/s3.svg) no-repeat;
  background-position: 118px 10px !important;
  border-radius: 5px;
  border: 1px solid var(--color-tertiary-grey-light, #b4b9c8);
  padding: 8px 12px;
  cursor: pointer;
  color: #646978;
  font-size: 15px;
}

.drop_selection p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.drop_data.open .selected_option {
  background-color: #fff;
  border-bottom: none;
  border-top: 1px solid #b4b9c8;
  border-left: 1px solid #b4b9c8;
  border-right: 1px solid #b4b9c8;
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.agent_dropdown {
}

.drop_selection p {
  color: var(--color-tertiary-grey, #646978);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  cursor: pointer;
}

.drop_selection {
  position: absolute;
  width: 100%;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px 12px;
  top: 100%;
  left: 0;
  border-right: 1px solid #b4b9c8;
  border-bottom: 1px solid #b4b9c8;
  border-left: 1px solid #b4b9c8;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.drop_data.open {
  border-radius: 5px;
  background: transparent;
  border: none;
}

.overall_right_side {
  position: relative;
}

/* single-page */
/* 
.single_posts p {
	padding: 0px 20px;
	margin-top: -16px;
} */
.post-data {
  padding: 20px 20px;
}

.left_post_detail {
  width: 70%;
}

.right_post_detail {
  width: 25%;
}

.single_entry label {
  width: 100%;
  color: var(--color-secondary-dark-mid, #3c4150);
  font-size: 20px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
}

.post_detail {
  flex-wrap: wrap;
  justify-content: space-between;
}

.single_entry .form-control {
  height: 51px;
}

.left_link_edit_title {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  width: 70%;
}

.edit_link {
  margin-top: 9px;
}

.edit_icon {
  margin-left: 50px;
  margin-top: -5px;
}

.edit_control {
  width: 100%;
  border: none;
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
  text-transform: lowercase;
}

.left_link_edit {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  text-transform: lowercase;
  width: 70%;
}

.tefe {
  justify-content: space-between;
  flex-wrap: wrap;
}

.l_tefe {
  width: 48%;
}

.r_tefe {
  width: 48%;
}

.r_tefe .favicon_uploader {
  margin-top: 25px;
  width: 100%;
  height: 281px;
}

.r_tefe .right_link_comp {
  width: 100%;
}

.r_tefe .favicon_uploader label {
  margin-top: 100px;
}

.single_posts p {
  padding: 0px 20px;
  margin-top: auto !important;
}

.r_tefe .favicon_uploader img {
  width: 100%;
  cursor: pointer;
  height: 277px;
  object-fit: cover;
  max-width: 100%;
  margin-top: -8px;
}

.r_tefe .favicon_label img {
  height: 49px !important;
  width: 49px !important;
  object-fit: inherit !important;
}

.r_tefe .close_fav {
  position: absolute;
  top: 2px;
  right: 4px;
  cursor: pointer;
}

.r_tefe p {
  margin-top: 8px !important;
}

.l_tefe {
  margin-top: 40px;
}

.select_single .form-control {
  background: url(../../assets/dropdown.svg) no-repeat;
  background-position: calc(100% - 10px) center;
}

.date_icon .form-control {
  background: url(../../assets/calender.svg) no-repeat;
  background-position: calc(100% - 10px) center;
}

.mdl {
  margin-left: 35px;
}

.labels_checks label {
  color: var(--color-tertiary-grey, #646978);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 40px;
}

.date_published {
  margin-top: 20px;
}

.tags_set p {
  padding: 0px !important;
  margin-top: 10px !important;
}

.tags_set label {
  margin-top: 5px;
}

.post-data .title_bar_setting .form-control {
  height: 51px;
}

.tags_dropdown {
  border-radius: 5px;
  border: 1px solid var(--color-white-blue, #f0f5ff);
  background: var(--color-white-pure, #fdfdfd) !important;
  padding: 20px;
  padding-top: 0px;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.tags_dropdown label {
  color: var(--color-tertiary-grey, #646978);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.tags_selector {
  position: relative;
}

.tags_selector .form-control {
  cursor: pointer;
}

.editor {
  margin-top: 50px;
}

/* Container Styles */
.react-datetime-picker {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  background-color: white;
}

/* Input Field Styles */
.react-datetime-picker__inputGroup {
  display: flex;
  flex-wrap: nowrap;
}

.react-datetime-picker__inputGroup__input {
  border: none;
  margin: 0;
  padding: 0 0.3rem;
  flex-grow: 1;
  outline: none;
}

/* Calendar Button Styles */
.react-datetime-picker__calendar-button {
  border: none;
  background: none;
  padding: 0.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.react-datetime-picker__calendar-button:enabled:hover {
  background-color: #e9ecef;
}

/* Calendar Icon Styles */
.react-datetime-picker__calendar-button__icon {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  fill: none;
}

/* Clock Icon Styles */
.react-datetime-picker__clock-button__icon {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  fill: none;
}

/* datepicker */
.react-datepicker-wrapper {
  width: 100%;
}

.all_dates {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid var(--color-white-blue, #f0f5ff);
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25);
  height: 51px;
  margin-top: 10px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background: url(../../assets/calender.svg) no-repeat;
  background-position: calc(100% - 10px) center;
}

.all_dates:focus {
  outline: none;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.react-datepicker {
  font-family: "Roboto", sans-serif !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--color-white-blue, #f0f5ff) !important;
  background: var(--color-white-pure, #fff) !important;
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25) !important;
}

.react-datepicker__header {
  text-align: center;
  background-color: #22a9e0 !important;
  border: 1px solid var(--color-white-blue, #f0f5ff) !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff !important;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__day {
  color: #494949 !important;
}

.react-datepicker__day--selected {
  border-radius: 100px !important;
  background: none !important;
  border-radius: 100px;
  border: 1px solid #22a9e0;
  color: #22a9e0 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #22a9e0 !important;
  color: white;
  font-weight: bold;
}

.ql-editor.ql-blank {
  min-height: 500px;
  border-radius: 0px 0px 4px 4px !important;
  border: 1px solid var(--color-white-blue, #f0f5ff) !important;
  background: var(--color-white-pure, #fff) !important;
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25) !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #f7f8f9 !important;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  border: none !important;
  background: var(--color-white-off, #f7f8f9) !important;
}

.ql-container.ql-snow {
  border: 1px solid #f0f5ff !important;
}

/* manage-tags */
.padd_app {
  padding: 0px 20px;
  color: var(--color-secondary-dark-mid, #3c4150);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: -7px;
}

.tag_name {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.count_tags {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.left_tags {
  width: 48%;
  border-radius: 4px;
  border: 1px solid var(--color-white-blue, #f0f5ff);
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25);
  height: 51px;
  padding: 16px;
  justify-content: space-between;
  margin-top: 24px;
}

.tag_list {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 50px;
}

.recycle_delete {
  background: none;
  border: none;
}

.tag_btns a {
  text-decoration: none;
}

.tag_btns {
  flex-wrap: wrap;
}

.tag_btns a {
  padding-left: 15px;
}

button.recycle_delete {
  padding-left: 15px;
}

.tag_btns span {
  font-size: 19px !important;
}

.manage_tags_set .two_btns {
  padding-left: 20px;
  padding-bottom: 30px;
}

.blog-setting-page h3 {
  color: var(--color-secondary-dark-mid, #3c4150);
  font-size: 20px;
  font-weight: 700;
  margin-top: 45px;
}

.timezone {
  margin-top: 35px;
}

.timezone label {
  width: 25%;
}

.area_comments {
  margin-top: 20px;
}

.comments_per_page {
  color: var(--color-tertiary-grey, #646978);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.comment_number {
  width: 40px;
  margin-top: -15px;
  margin-left: 13px;
}

.comment_number .form-control {
  height: 35px;
  padding: 5px;
}

/* tag-modal-css added on 27-11-2023 */
.tag_add_new .form-control {
  width: 100%;
  height: 47px;
  margin-left: 0px !important;
}

.tag_add_new h3 {
  color: var(--color-secondary-dark-light, #505564);
  font-size: 16px;
  font-weight: 700;
  margin-top: 0px !important;
}

.tagger .modal-body {
  padding: 32px !important;
  padding-bottom: 6px !important;
}

.tagger {
  padding: 32px;
}

.manage_tags_set .modal-dialog {
  width: 380px;
}

.tagger .btn {
  padding: 13px 51px !important;
}

.tagger .two_btns {
  padding-left: 0px !important;
}

.paltu {
  padding-left: 20px !important;
}

.col-md-12.col-sm-12.d-flex.justify-content-end.mku.ctu {
  margin-top: -34px;
}

.all_dates {
  cursor: pointer;
}

.ql-editor {
  min-height: 500px;
}

.add-tags-link {
  text-decoration: underline;
  color: #22a9e0 !important;
}

@media (min-width: 768px) and (max-width: 1124px) {
  .left_tags {
    width: 100%;
  }
}

@media (min-width: 443px) and (max-width: 767px) {
  .tag_name {
    margin-top: 10px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .post-data {
    padding: 0px 20px;
  }

  .left_post_detail {
    width: 100%;
  }

  .right_post_detail {
    width: 100%;
  }

  .single_entry label {
    font-size: 14px;
  }

  .l_tefe {
    width: 100%;
  }

  .r_tefe {
    width: 100%;
  }

  .left-section input[type="text"] {
    margin-top: 20px;
  }

  .icon-pencil,
  .icon-eye,
  .icon-search,
  .icon-bell {
    font-size: 15px !important;
  }

  .left-section {
    width: 92%;
    margin-top: -9px;
  }

  .left-section span {
    margin-top: 20px;
  }

  .blog_add {
    text-align: right;
    margin-top: -95px;
    padding: 28px 10px;
  }

  .update_btn {
    font-size: 14px !important;
    padding: 9px 22px !important;
  }

  .search_area {
    margin-right: 0px !important;
    width: 100%;
    display: block !important;
  }

  .dropper .dropdown-menu.show {
    left: -59px;
  }

  .table th,
  .table td {
    font-size: 13px !important;
  }

  .posting_area table td {
    padding: 15px 10px !important;
  }

  .mku {
    flex-wrap: wrap !important;
  }

  .search_bloxk {
    margin-right: 0px !important;
    margin-top: 21px !important;
    margin-bottom: 10px !important;
  }

  .btn_block {
    position: absolute;
    top: 363px;
  }

  .btn_block button {
    padding: 0px 25px;
    font-size: 15px;
  }

  .tag_list {
    padding: 0px 10px;
  }

  .left_tags {
    width: 100%;
  }

  .tag_name {
    font-size: 13px;
    width: 70%;
  }

  .tag_btns span {
    font-size: 15px !important;
  }

  .tag_btns a {
    padding-left: 6px;
  }

  .left_tags {
    height: 51px;
    padding: 6px;
    margin-top: 24px;
  }

  .tag_btns {
    flex-wrap: wrap;
    width: 29%;
    margin-top: 6px;
  }

  button.recycle_delete {
    padding-left: 7px;
  }

  .cancel_btn {
    padding: 9px 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .stable_view .table-responsive {
    margin-top: 30px;
  }

  .col-md-6.col-sm-12.d-flex.justify-content-start.align-items-center.link_sett.paltu.jeter.swagger {
    margin-bottom: -183px !important;
    padding-left: 0px !important;
  }

  .blogger_area .padding {
    display: block;
  }

  .blogger_area .mku {
    margin-top: 30px;
  }

  .blogger_area .search_area {
    margin-right: 35px;
    width: 100%;
  }

  .blogger_area .search_block {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .col-md-6.col-sm-12.d-flex.justify-content-start.align-items-center.link_sett.paltu.jeter.swagger {
    margin-bottom: -304px !important;
    padding-left: 0px !important;
  }

  .col-md-12.col-sm-12.d-flex.justify-content-end.mku {
    width: 100%;
    justify-content: right;
    margin-left: 0%;
  }

  .blog_add {
    text-align: right;
    margin-top: -58px !important;
    padding: 28px 10px;
    margin-right: 0px !important;
  }

  .stable_view .table-responsive {
    margin-top: 30px;
  }

  .blogger_area .search_block {
    width: 100% !important;
  }

  .blogger_area .filter_area {
    margin-top: 20px !important;
  }

  .blogger_area .search_block .form-control {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .col-md-6.col-sm-12.d-flex.justify-content-start.align-items-center.link_sett.paltu.jeter.swagger {
    margin-bottom: -304px !important;
    padding-left: 0px !important;
  }

  .col-md-12.col-sm-12.d-flex.justify-content-end.mku {
    width: 100%;
    justify-content: right;
    margin-left: 0%;
  }

  .blog_add {
    text-align: right;
    margin-top: -58px !important;
    padding: 28px 10px;
    margin-right: 0px !important;
  }

  .stable_view .table-responsive {
    margin-top: 30px;
  }

  .blogger_area .search_block {
    width: 100% !important;
  }

  .blogger_area .filter_area {
    margin-top: 20px !important;
  }

  .blogger_area .search_block .form-control {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}

/* pagination */
.all_pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.all_pagination li a {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  text-align: center;
  margin: 25px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-decoration: none;
}

.all_pagination li {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 5px 0px;
}

.all_pagination .active {
  border-radius: 41px;
  background: var(--color-secondary-dark, #141928);
  color: #fff;
}

.raro,
.laro {
  display: inline;
}

.raro img {
  transform: rotate(180deg);
}
