button.collapse-btn.btn-custom {
  /* position: absolute; */
  margin-top: 20px;
}

.option a {
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px;
  margin-top: 4px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.option a:hover {
  background-color: #3c4150;
}

.option-icon {
  margin-right: 10px;
}

.sub-option a {
}

.collapse-btn:focus {
  outline: none;
}

.option-link,
.option-link:visited {
  color: inherit;
  /* Your desired color */
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* .option:hover,
.option.active {
  background-color: #3C4150;
  color: #fff;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
 */

/* .setting_only {
    display: inherit;
  }
  
  ul.sub-options {
    margin: 0px;
    padding: 0px;
  }
  
  li.sub-option {
    list-style-type: none;
    text-align: left;
  }
  
  .sub-options a {
    text-decoration: none;
    color: var(--color-tertiary-grey-light, #B4B9C8) !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 150%;
  }
  
  
  .icon-Setting {
    margin-left: -3px;
  }
  
  .setting_only a.option-link.active {
    margin-top: -7px;
  }
  
  li.sub-option {
    list-style-type: none;
    text-align: left;
    line-height: 39px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    gap: 8px;
    margin: 10px;
    margin-left: 0px;
    margin-top: 17px;
  }
  
  .setting_only span {
    font-size: 21px !important;
  }
  
.blog_only .option-icon {
  margin-left: -15px;

}

   */

li.sub-option {
  list-style-type: none;
}
.blog_only {
  padding-bottom: 5px;
  padding-top: 1px;
  border-top: none;
}

.sub-option a {
  line-height: 0px;
  text-decoration: none;
  color: var(--color-tertiary-grey-light, #b4b9c8) !important;

  font-weight: 400;
  line-height: 150%;
  font-size: 15px;
}

.sub-option a span {
  font-size: 21px !important;
  margin-right: 10px;
}

ul.sub-options {
  margin: 0px;
  padding: 0px;
}

svg.svg-inline--fa.fa-chevron-down.icon-chevron-down.rotated {
  text-align: revert;
  margin-left: 58px;
}

.icon-chevron-down.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-out;
  margin-left: 58px !important;
  font-size: 12px;
  color: #787d8c;
}

svg.svg-inline--fa.fa-chevron-down.icon-chevron-down {
  font-size: 12px;
  color: #787d8c;
  margin-left: 58px;
}

.option-link.active {
  background-color: #3c4150;
}

.setting_only .option-link.active {
  background: #141928 !important;
}

.logo-image {
  width: 103px;
  height: 54px;
  /* object-fit: contain;  */
  /* This ensures the image fits without stretching */
  object-fit: fill;
}
