.yellow .row{ width: 100%;}




@media (min-width: 320px) and (max-width:1024px) {

    .kailow .row{ width: 100%;}

}

.branding-error {
    color: red !important;
    font-size: 12px;
}