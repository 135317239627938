.rel_heading {
  color: var(--Color-Secondary-Dark-Mid, #3c4150);
  font-size: 20px;
  font-weight: 700;
  font-family: "Roboto", sans-serif !important;
}
.real_mls {
  flex-wrap: wrap;
  justify-content: space-between;
}
.mls_bts_row {
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.left_real_list {
  width: 48%;
}
.right_real_mls {
  width: 48%;
}

.left_real_list_realtor {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px #508ff41a;
  padding: 20px;
  border-radius: 10px;
}

.left_real_list_mls {
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px #508ff41a;
  padding: 20px;
  border-radius: 10px;
  /* min-height: 500px; */
  overflow: auto;
}

.right_real_mls_payment {
  border: 1px solid #f7f8f9;
  border-radius: 8px;
  padding: 15px;
}

.payment_body_text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #646978;
}

.payment_body_text span {
  font-size: 18px;
  color: #646978;
  line-height: 23.44px;
  font-weight: 700;
  font-family: "Roboto", sans-serif !important;
}

.payment_body_text a {
  color: #22a9e0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.payment_body_text .domain-link {
  font-size: 22px;
  color: #646978;
  line-height: 23.44px;
  font-weight: 700;
  font-family: "Roboto", sans-serif !important;
  text-decoration: none;
}

.payment-logout {
  color: #22a9e0;
  border: 1px solid #22a9e0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff;
  height: 41px;
  width: 158px;
}

.subscription_text {
  color: #787d8c;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.margin_Top {
  margin-top: 35px;
}

.custom-radio-container-payment {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  color: #646978;
}

.custom-radio-container-payment .custom-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.add-realtor-link {
  text-decoration: underline;
  color: #22a9e0;
  margin-top: 10px;
  font-size: 16px;
}

.radio-checkmark-payment {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #22a9e0;
}

.custom-radio-container-payment .custom-radio:checked ~ .radio-checkmark {
  background-color: #fff;
}

.radio-checkmark-payment:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-container-payment .custom-radio:checked ~ .radio-checkmark-payment:after {
  display: block;
}

.custom-radio-container-payment .radio-checkmark-payment:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #22a9e0;
}

.hidden_selector_realtor .rc-slider-rail {
  background-color: #f0f5ff !important;
  height: 6px;
}

.hidden_selector_realtor .rc-slider-track {
  background-color: #22a9e0 !important;
  height: 6px;
}

.hidden_selector_realtor .rc-slider-handle {
  border-color: #22a9e0 !important;
  height: 20px;
  width: 20px;
  margin-left: -10px;
  margin-top: -5px;
  background-color: #22a9e0;
}

.hidden_selector_realtor .rc-slider-handle {
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background-color: #22a9e0 !important;
  border: solid 2px #22a9e0 !important;
  border-radius: 50%;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

.rel_links {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  margin-top: 5px;
}
.tb_calc {
  padding: 16px 50px !important;
}
.number_left {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Oxygen;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.number_right {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Oxygen;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.numbers_mls {
  text-align: center;
  font-size: 20px;
  color: #646978;
  font-weight: 400;
  line-height: 25.25px;
}
.mls_text {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.mls_text a {
  color: var(--Color-Primary-Blue, #22a9e0);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.mls_listing_images {
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.singleadd_mls img {
  width: 100px;
  width: 100px;
  border-radius: 500px;
}
.mls_listing_images {
  margin-top: 45px;
}
.left_mls_image img {
  border-radius: 5px;
  border: 1px solid var(--Color-White-Blue, #f0f5ff);
  width: 66px;
  height: 66px;
}
.mls_looping {
  margin-top: 20px;
}
.right_mls_data h2 {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: "Oxygen", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px !important;
  padding: 0px !important;
}
.right_mls_data p {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: "Oxygen", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px !important;
  margin-bottom: 5px !important;
}
.right_mls_data h3 {
  margin-top: 3px !important;
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.right_mls_data {
  margin-top: 3px !important;
}
.single_mls_detail {
  border-radius: 4px;
  gap: 15px;
  border: 1px solid var(--Color-White-Off, #f7f8f9);
  padding: 16px;
  padding-bottom: 10px;
}
.single_mls_detail {
  margin-top: 18px;
}
.login_stripe {
  color: var(--Color-Primary-Green, #80c342);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Green, #80c342);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 30px;
  background-color: transparent !important;
}
.left_stripe {
  align-items: center;
  gap: 15px;
  width: 67%;
}
.right_stripe {
  width: 20%;
}
.stripe_area {
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 70px;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 10px;
}
.full_striope {
  background: var(--Color-White-Off, #f7f8f9);
  width: 80%;
  margin: 0 auto;
}
/* Payment-packages */
.breads li {
  display: inline-block;
  color: var(--Color-Secondary-Dark-Mid, #3c4150);
  font-size: 14px;
  font-weight: 400;
}
.breads li a {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}
.breadcrumb-item::before {
  display: none;
}
.payment-broker h1 {
  color: var(--Color-Secondary-Dark-Mid, #3c4150);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 70px;
}
.top_table_row {
  justify-content: space-between;
  padding: 0px 50px;
}
.right_t_row {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  text-align: right;
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
  /* 27.84px */
}
.number_list_count,
.wovnn_commission,
.dollar_symbol {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 174%;
}
.breadcrumb-item {
  display: flex;
  align-items: center;
}
.breadcrumb-arrow {
  margin: 0 0px;
  /* Adjust the spacing around the arrow */
}
.mls_listing_invoice th {
  color: var(--Color-White-Pure, #fff) !important;
  /* Heading 6 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background: #646978 !important;
  padding: 16px 50px !important;
}
.mls_listing_invoice td {
  padding: 16px 50px !important;
  vertical-align: middle !important;
  border: none !important;
}
.payment-broker {
  border-radius: 10px;
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 22px 0px;
  height: 97vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.payment-success {
  padding: 40px 190px 10px 190px;
}

.td-locations {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-weight: 400;
}
.td-price {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.td-image span {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Heading Span */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}
.td-delete img {
  cursor: pointer;
}
.td-image img {
  height: 40px;
  width: 40px;
}
.mls_listing_invoice .table tr:nth-child(even) {
  background-color: var(--Color-White-Off, #f7f8f9);
  --bs-table-bg: none;
  border: none;
  border-bottom: none;
}
.btn_calc {
  justify-content: center;
  background: #f0f5ff;
  padding: 20px 0px;
}
.next_btns {
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Green, #80c342);
  background: var(--Color-Primary-Green, #80c342);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  color: var(--Color-White-Pure, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
  padding: 13px 50px !important;
  width: fit-content !important;
}
.table_calc {
  color: var(--Color-Tertiary-Grey, #80c342);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0px;
}
.proce_btn_set td {
  padding: 10px 50px;
}
.mls_listing_invoice {
  margin-top: 50px;
}
.full_table_area .btn_block .btn {
  border-radius: 4px;
  border: 1px solid var(--color-primary-blue, #80c342);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 0px 35px;
  color: var(--color-primary-blue, #80c342);
  height: 41px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  background: #fff;
}
.full_table_area tfoot td {
  background: #f0f5ff !important;
  margin-top: 50px !important;
  padding: 16px 50px;
}
.choose_package_only {
  margin-top: -70px;
}
/* Payment-packages */
.choose_package_only td {
  color: var(--Color-Tertiary-Grey, #646978);
  font-size: 16px;
  font-weight: 400;
}
.choose_package_only h4 {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Body Normal */
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}
.choose_package_only p {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 8px;
}
.choose_package_only h3 {
  color: var(--Color-Secondary-Dark-Mid, #3c4150);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.choose_package_only .form-control {
  border-radius: 4px;
  border: 1px solid var(--Color-White-Blue, #f0f5ff);
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25);
  width: 75px;
  height: 34px;
}
.choose_package_only td span {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}
.smaller_fonts {
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;
  /* Body More Small */
  font-family: Oxygen;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}
.center-th {
  text-align: center;
}
.font-boldwe {
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;
  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.single-agent-class {
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;
  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.domain_left {
  background: var(--Color-White-Off, #f7f8f9);
}

.domain-btn:disabled,
.domain-btn[disabled] {
  background-color: #fff;
}

.register_domain {
  padding: 0px 50px;
}
.register_domain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.domain_left {
  width: 48%;
  padding: 32px;
}
.dos_image {
  text-align: center;
  margin-top: 40px;
}
.domain_check .search_bloxk .form-control {
  width: 300px;
}
.domain_check h3 {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Heading 6 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.domain_check .search_area {
  margin-top: 15px;
  position: relative;
}
.www {
  color: var(--Color-Tertiary-Grey, #646978);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 7px;
  margin-right: 8px;
}
.domain_check .btn_block button {
  border: 1px solid #80c342;
  color: #80c342;
  width: 143px;
  padding: 0px;
}
.or {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
  display: inline-block;
  left: 240px;
  top: 1px;
}
.or::before,
.or::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100px;
  background: #b4b9c8;
  top: 50%;
}
.or::before {
  left: -120px;
  transform: translateY(-50%);
}
.or::after {
  right: -120px;
  transform: translateY(-50%);
  /* Center vertically */
}
.second_domain p {
  color: var(--Color-Tertiary-Grey, #646978);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 50px;
}
.bot_tab_btns {
  margin-top: 30px;
  background: #f0f5ff;
  padding: 30px 0px;
}
.total_count_table tr {
  border-bottom: 1px solid #f0f5ff !important;
}
.total_count_table .table tr:nth-child(even) {
  background-color: transparent !important;
  --bs-table-bg: none;
  border: none;
  border-bottom: 1px solid #f0f5ff !important;
}
.total_count_table td {
  padding: 10px 50px;
}
.rt_th {
  text-align: right;
}
.second_one {
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: right;
  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.number_cont {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8) !important;
  text-align: right;
  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}
.main_pricee {
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: right;
  /* Heading 5 */
  font-family: Roboto;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
span.number_cont {
  margin-right: 10px;
  position: relative;
  top: -1px;
}
.full_totaling {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.full_connectror {
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 10px 50px;
  justify-content: space-between;
}
.left_connectors {
  color: var(--Color-Tertiary-Grey, #646978);
  width: 60%;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}
.right_connectors {
  width: 38%;
}
.right_connectors .custom-radio-container {
  color: var(--Color-Tertiary-Grey, #646978);
  /* Body More Small */
  font-family: Oxygen;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}
.right_connectors .selectors {
  margin-top: 0px;
}
.right_connectors .selectors h3 {
  color: var(--Color-Tertiary-Grey, #646978);
  margin-bottom: 15px !important;
  text-align: right;
  /* Heading 6 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.right_connectors {
  width: 38%;
  display: flex;
  justify-content: right;
}
.invoice-checks {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/no-check.svg");
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.invoice-checks:checked {
  background-image: url("../../assets/checks.svg");
  background-size: cover;
  background-position: center;
  border: none;
  height: 30px;
  width: 30px;
}
.ttp {
  margin-top: -40px;
  color: var(--Color-Tertiary-Grey, #646978);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.full_billing_info h3 {
  color: var(--Color-Tertiary-Grey, #646978);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 30px;
}
.single_bill_data {
  color: var(--Color-Secondary-Dark-light, #505564);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 26px;
  padding-left: 29px;
}
.ba1 {
  background: url(../../assets/b1.svg) no-repeat;
  background-position: 0px;
}
.ba2 {
  background: url(../../assets/b2.svg) no-repeat;
  background-position: 0px;
}
.ba3 {
  background: url(../../assets/b3.svg) no-repeat;
  background-position: 0px;
}
.ba4 {
  background: url(../../assets/b4.svg) no-repeat;
  background-position: 0px;
}
.ba5 {
  background: url(../../assets/b5.svg) no-repeat;
  background-position: 0px;
}
.verifies_text {
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.verifies_image {
  margin-left: 5px;
}
.left_billing_info,
.right_billing_info {
  width: 48%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checker {
  margin-top: -15px;
  position: relative;
  top: -9px;
  margin-left: 5px;
}

.main_numbers .number_left {
  font-family: "Roboto", sans-serif !important;
}
.number_right {
  font-family: "Roboto", sans-serif !important;
}
.gapping {
  gap: 20px;
  margin-top: 15px;
}

.CircularProgressbar .CircularProgressbar-text {
  color: #646978 !important;
  font-size: 24px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke-width: 12px !important;
  stroke: #22a9e0 !important;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 12px !important;
}

.CircularProgressbar {
  padding: 0px;
  overflow: visible;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #646978 !important;
  font-size: 20px;
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .maker {
    flex-wrap: wrap;
  }
  .ctbtt {
    margin-top: 20px;
  }
}

@media (min-width: 668px) and (max-width: 767px) {
  .mls_text {
    font-size: 14px;
  }
  .payment-success {
    padding: 50px 40px 0px 40px;
  }
  .right_mls_data h2 {
    font-size: 16px !important;
    margin-bottom: 5px !important;
  }
  .left_real_list_mls {
    min-height: inherit;
    padding: 10px;
    border-radius: 10px;
    /* max-height: 500px; */
    overflow: auto;
  }
}

@media (min-width: 320px) and (max-width: 667px) {
  .mls_bts_row {
    margin-top: -10px;
  }
  .payment-success {
    padding: 50px 40px 0px 40px;
  }
  .number_left {
    font-size: 53px;
  }
  .gapping {
    gap: 20px;
    margin-top: 15px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .mls_text {
    font-size: 14px;
  }
  .right_mls_data h2 {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }
  .left_real_list_mls {
    min-height: inherit;
    padding: 10px;
    border-radius: 10px;
    /* max-height: 500px; */
    overflow: auto;
  }
  .maker {
    flex-wrap: wrap;
  }
  .cpflex {
    flex-wrap: wrap;
    margin-left: 0px !important;
    margin-bottom: 8px;
  }
  .tflex {
    margin-top: 15px;
    margin-left: 20px !important;
    margin-top: 0px;
  }
  .payment_body_text {
    margin-bottom: 20px !important;
  }
  .right_real_mls_payment {
    margin-top: 20px;
  }
  .right_real_mls_payment {
    flex-wrap: wrap;
  }
  .payment-logout {
    margin-top: 20px;
  }
}

/* *************************** */

@media (min-width: 1025px) and (max-width: 1260px) {
  .domain_check .search_bloxk .form-control {
    width: 190px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .domain_check .search_bloxk .form-control {
    width: 450px !important;
  }
  .or {
    left: 116px !important;
    top: 31px !important;
  }
  .domain_check .btn_block {
    position: absolute !important;
    top: 0px !important;
    right: 0;
  }
  .single_domain.second_domain {
    margin-top: 20px !important;
  }
  .second_domain .btn_block {
    top: 0px !important;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .ttp {
    margin-top: 0px;
  }
  .choose_package_only {
    margin-top: 0px;
  }
  .left_real_list {
    width: 100%;
  }
  .set_payments h2 {
    margin-bottom: 15px;
  }
  .right_real_mls {
    width: 100%;
  }
  .left_mls_image img {
    width: 35px;
    height: 35px;
  }

  .full_striope {
    width: 100%;
    text-align: center;
    padding: 0px 20px;
    padding-bottom: 10px;
  }
  .left_stripe {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .right_stripe {
    width: 100%;
  }
  .stripe_area {
    gap: 10px;
  }
  .payment-broker h1 {
    font-size: 26px !important;
  }
  .mls_listing_invoice td {
    padding: 10px 8px;
  }
  .mls_listing_invoice th {
    padding: 16px 8px;
  }
  .choose_package_only p {
    font-size: 12px;
    line-height: 20px;
  }
  .breads li a {
    font-size: 12px;
  }
  .breads li {
    font-size: 12px;
  }
  .next_btns {
    padding: 13px 35px;
    margin-right: 10px;
  }
  .top_table_row {
    padding: 0px 10px;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .top_table_row .btn_block {
    position: absolute;
    top: 166px;
    right: 19px;
  }
  .td-image img {
    height: 20px;
    width: 20px;
  }
  .left_t_row {
    margin-top: -51px;
  }
  .td-image span {
    font-size: 12px;
    margin-left: 0px;
  }
  .mls_listing_invoice td {
    padding: 10px 0px;
  }
  .td-delete img {
    height: 20px;
    width: 20px;
  }
  .top_table_row .btn_block {
    display: none;
  }
  .mls_listing_invoice td {
    padding: 10px 14px;
    font-size: 15px;
  }
  .register_domain {
    padding: 0px 10px;
  }
  .domain_left {
    width: 100%;
    padding: 18px;
  }
  .domain_left p {
    font-size: 14px;
  }
  .dos_image {
    text-align: center;
    margin-top: 10px;
  }
  .domain_check .search_bloxk .form-control {
    width: 250px;
  }
  .or {
    left: 116px;
    top: 31px;
  }
  .domain_check .btn_block {
    position: absolute;
    top: 94px;
  }
  .single_domain.second_domain {
    margin-top: 20px;
  }
  .second_domain .btn_block {
    top: 94px;
  }
  .second_domain p {
    margin-top: 90px;
  }
  .ttp {
    font-size: 14px;
    padding: 0px 10px;
  }
  .full_billing_info {
    padding: 10px;
    flex-wrap: wrap;
  }
  .left_billing_info,
  .right_billing_info {
    width: 100%;
  }
  .single_bill_data {
    font-size: 14px;
  }
  .main_pricee {
    font-size: 13px !important;
  }
  .choose_package_only td span {
    font-size: 10px;
  }
  .full_connectror {
    margin-bottom: 0px;
    padding: 10px 10px;
  }
  .left_connectors {
    width: 100%;
    font-size: 14px;
  }
  .right_connectors {
    width: 100%;
  }
  .right_connectors .selectors h3 {
    margin-bottom: 15px !important;
    font-size: 15px;
    margin-top: 40px;
  }
  .payment-broker h1 {
    margin-bottom: 10px;
  }
}
.curtain {
  width: 180px;
  margin-top: 40px !important;
  margin-bottom: 40px;
  margin: 0 auto;
}
.loading-span {
  display: flex;
}
.realtor-input input[type="number"]::-webkit-inner-spin-button,
.realtor-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.td-image .Total_price {
  color: var(--Color-Tertiary-Grey, #80c342);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}
.full_table_area tfoot .next_button {
  background: #ffffff !important ;
  margin-top: 50px !important;
  padding: 16px 50px;
}


.package-upgrade th{
      background: #646978 !important;
    color: #fff !important;
    color: var(--Color-White-Pure, #fff) !important;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding: 16px 50px !important;
}
.package-upgrade{
  width: 100%;
}
.package-info-price{
  text-align: center;
}