.wovnn_owe {
	color: var(--Pastel-Dark-Red, #28AC47);
	text-align: right;
	font-family: Oxygen;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 18px */
}


.main_transaction_page table td {
	color: #787D8C !important;
}


.pamentse_link .price_numberss {
	color: var(--Color-Secondary-Dark-light, #505564);


	/* Heading 5 */
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.pamentse_link p {
	color: var(--Color-Primary-Blue, #80C342);
	font-family: Oxygen;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 10px;
	margin-top: 10px;
}

.pamentse_link {
	text-align: center;
}

.alignment,
.wovnn_amount {
	text-align: center !important;
}

.alignment_right {
	text-align: right !important;
}

.main_transaction_page .status {
	text-align: right;
}

.wovnn_amount {
	color: var(--Color-Secondary-Dark-light, #505564) !important;
	text-align: center;



	font-size: 20px !important;

	font-weight: 700 !important;
}

.delquent {
	color: var(--Pastel-Dark-Orange, #FF9600) !important;
	font-family: Oxygen;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 0px;

}

.pendinggs {
	color: #ED4949 !important;
	font-family: Oxygen;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 0px;
}


.main_transaction_page .success {
	color: #28AC47 !important;

}

.dsasher {
	color: var(--Color-Secondary-Dark-light, #505564) !important;

	font-size: 18px !important;

	font-weight: 700 !important;
}


.successess {
	color: #28AC47 !important;
	font-family: Oxygen;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 0px;
}

.results_realtor {
	color: var(--Color-Tertiary-Grey-mid, #787D8C);

	/* Heading Span */
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.transactions_listing {
	padding-left: 25px;
	margin-top: 0px;
}

.main_transaction_page {
	margin-top: 10px;
}



.main_transaction_page .blacking {
	font-size: 16px !important;
}


.main_transaction_page .posting_area table td {
	font-size: 14px;
}

.wovnn_amount p {
	color: var(--Color-Primary-Blue, #80C342);
	font-family: Oxygen;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 10px;
	margin-top: 10px;
}


.qustion_mark {
	margin-right: 5px;
}

.loading-spinner-container {
	display: flex;
	justify-content: center;
	/* Center horizontally */
	align-items: center;
	/* Center vertically */
	height: 100vh;
	/* Set the container height to full viewport height */
}

.succeeded{ font-size: 12px; color: #28AC47; font-family: 'Oxygen', sans-serif !important; font-weight: 400 !important;}
.failed{ font-size: 12px; color: #ED4949; font-family: 'Oxygen', sans-serif !important; font-weight: 400 !important;}

.pamentse_link p, .wovnn_amount p{ line-height: 22px;}

.succeeded{ font-size: 12px; color: #28AC47; font-family: 'Oxygen', sans-serif !important; font-weight: 400 !important;}
.failed{ font-size: 12px; color: #ED4949; font-family: 'Oxygen', sans-serif !important; font-weight: 400 !important;}



@media (min-width: 768px) and (max-width:1024px) {
	.traansaction .transactions_listing {
		padding-left: 0px;
		
	}
	.traansaction .search_bloxk {
		margin-left: 20px !important;
		
	}
		
	.traansaction .results_realtor {
		margin-top: 65px !important;
		margin-left: -23px;
	}
	.traansaction .link_sett {
		
		padding-left: 56px;
	}

}

@media (min-width: 320px) and (max-width:767px) {

	.traansaction .transactions_listing {
		padding-left: 0px;
		
	}
	.traansaction .search_bloxk {
		margin-left: 20px !important;
		
	}
		
	.traansaction .results_realtor {
		margin-top: 65px !important;
		margin-left: -23px;
	}
	.traansaction .link_sett {
		
		padding-left: 56px;
	}

}
.percentByadmin {
    color: #ff3434;
font-size: 12px;
    font-weight: 500 !important;
}
.brokerName{
	    color: #30d4cf;
}