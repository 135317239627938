
.delete-modal .modal-content {
	border-radius: 15px;  width: 375px;
  }
  
  .delete-modal .modal-header {
	border-bottom: none; 
	padding: 1.5rem;
  }
  
  .delete-modal .modal-title {
	font-weight: bold; 
  }
  
  .delete-modal .modal-body {
	padding: 1.5rem; 
  }
  
  .delete-modal .modal-footer {
	border-top: none;
	padding: 1.5rem; 
  }
  
  .delete-modal .btn-secondary {
	background-color: #f0f0f0; 
	color: #333; 
	border-radius: 4px;
border: 1px solid var(--Color-Tertiary-Grey-light, #B4B9C8);
background: var(--Color-White-Pure, #FFF); color: var(--Color-Secondary-Dark, #141928);
text-align: center;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
  }
  
  .delete-modal .btn-danger {
	background-color: #dc3545; 
	color: #fff; 
  }
  
  .delete-modal .btn {
	border-radius: 5px; 
  }
  

  .delete-modal .modal-body {
    padding: 50px 30px;
    text-align: center;
    position: relative;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(72, 72, 72, 0.20);
}
  .delete-modal  .modal_deklete{ position: absolute; top: 10px; right: 10px; }

  .delete-modal-header img{ margin-bottom: 50px;}
  .delete-modal-header h5{ color: var(--Color-Secondary-Dark, #141928);
	font-family: Roboto;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;}

	.delete-modal p {
		color: var(--Color-Tertiary-Grey-mid, #787D8C) !important;
		text-align: center;
		font-family: Oxygen;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		margin-top: 15px;
	}

		.delete-modal-actions {
			display: flex;
			gap: 20px;
			justify-content: center;
			margin-top: 50px;
		}
		
		.delete-modal-actions button{ min-height: 47px;
			padding: 15px 35px; color: var(--Color-White-Pure, #FFF);
			text-align: center;
			
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; /* 100% */}

			