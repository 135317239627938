
.signup_realtor .log_reg{ justify-content: center;}
.signup_realtor .log_reg .btn{ color: var(--color-secondary-dark, #141928);
    text-align: center;
    
    /* Heading 5 */
    font-family: Roboto;
    font-size: 20px;
    
    font-weight: 700; border-bottom-color: #141928;
   }

/* signup-realtor */