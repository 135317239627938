.dashboard-container {
  display: flex;
}

.sidebar-main {
  background-color: #141928;
  min-height: 100vh;
}

.header-dasboard {
  flex-grow: 1;
}

.header-dasboard {
  flex-grow: 1;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* css for full sidebar */
span.icon-upper-arrow {
  position: relative;
  right: -50px;
  top: 4px;
}

.icon-upper-arrow {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.icon-upper-arrow.down {
  transform: rotate(0deg) !important;
}

.middle_sidebar.collapsed {
  margin-left: 1px !important;
}

.sidebar {
  width: 190px;
  height: 100%;
  background-color: #141928;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 50px;
}

.logo {
  font-size: 24px;
  padding: 20px 0;
  font-weight: bold;
}

.options {
  list-style-type: none;
  padding: 0;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: var(--color-tertiary-grey-light, #b4b9c8) !important;
}

.middle_sidebar {
  width: 95% !important;
  margin-left: 6px !important;
}

/* .dashboard {
  padding: 20px;
} */
.pitials {
  height: 54px !important;
  width: 54px !important;
}

.dashboard-container {
  display: flex;
  /*   height: 100%; */
}

.header-dasboard {
  width: 100%;
  background: #f2f4f9;
}

.header {
  background-color: #141928;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  margin: 0;
  font-size: 24px;
}

.header-nav {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 15px;
}

.header-nav-item {
  cursor: pointer;
}

.header-user {
  cursor: pointer;
}

a.option-link {
  text-decoration: none;
  color: var(--color-tertiary-grey-light, #b4b9c8) !important;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 150%;
}

.logo-image-small {
  width: 30px;
}

.d_board_only .teammate img {
  width: 54px;
  height: 54px;
}

.arrow-icon {
  width: 24px;
  height: auto;
  transition: transform 0.3s ease-in-out;
  margin-right: 14px;
}

.collapse-btn.collapsed .arrow-icon {
  transform: rotate(180deg);
}

.btn-custom {
  background: none;
  border: none;
  color: #fff;
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0px;
  /*     position: ; */
  bottom: 20px;
}

.option.border-bottom {
  border-bottom: 1px solid #3c4150 !important;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.sidebar-main {
  min-height: 100vh;
}

.options .collapsed {
  width: 100%;
  margin-left: 0px;
}

/*   header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  padding-right: 0px;
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

/* .left-section p,
.left-section a,
.right-section div,
.right-section svg {
  margin-right: 10px;
} */
.search-box {
  position: relative;
}

.search-box input {
  padding-right: 30px;
  border-radius: 5px;
  border: 0.5px solid var(--color-secondary-dark-light, #505564);
  height: 35px;
  background: none;
  color: #fff;
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  width: 211px;
  padding-left: 15px;
  padding-right: 38px;
}

.search-box svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.left-section p {
  margin-top: 20px;
  color: var(--color-white-pure, #fff);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.left-section {
  gap: 15px;
}

.icon-pencil,
.icon-eye,
.icon-search,
.icon-bell {
  font-size: 22px !important;
}

.left-section a {
  text-decoration: none;
}

span.icon-eye {
  position: relative;
  top: 0px;
}

/* .left-section input[type="text"] {
    background: #141928; border-bottom: 1px solid #B4B9C8; color: #fff; border-top: 0px; border-left: 0px; border-right: 0px; font-size: 14px;
} */
.left-section input[type="text"] {
  background: #141928;
  border-bottom: none;
  color: #fff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 14px;
  color: #fff !important;
  width: 184px;
}

.left-section input[type="text"]::placeholder {
  color: #fff;
}

.right-section {
  gap: 15px;
}

.search-box {
  position: relative;
}

span.icon-search {
  position: absolute;
  right: 11px;
  top: 7px;
}

.bell_notification {
  margin-top: 6px;
  position: relative;
}

span.notification_count {
  position: absolute;
  top: -6px;
  background: red;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  padding: 0px;
  font-size: 9px;
  left: 14px !important;
  text-align: center;
}

/* dashboard */
.block {
  border: 1px solid #ddd;
  border-radius: 0 20px 0 0;
  position: relative;
  margin-bottom: 20px;
}

.icon-bg {
  background-color: #4caf50;
  /* Green */
  border-radius: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  color: white;
}

.chart-space {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50px;
}

.number {
  font-size: 24px;
  margin: 60px 0 10px;
}

.block {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
  padding: 0px 14px !important;
}

.icon-bg {
  background-color: #4caf50;
  border-radius: 100%;
  padding: 8px;
  position: absolute;
  top: 18px;
  left: 14px;
  font-size: 27px;
}

.icon-vector4,
.icon-vector0,
.icon-Vector1,
.icon-Vector2 {
  /* Include your styling or path to the icons here */
}

.chart-space {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50px;
}

.number {
  color: var(--color-secondary-dark, #141928);
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-top: 70px;
}

.block-color1 .block {
  background: #cce4ff;
}

.block-color2 .block {
  background: var(--pastel-light-orange, #ffeacc);
}

.block-color3 .block {
  background: var(--pastel-light-green, #d4eeda);
}

.block-color4 .block {
  background: var(--pastel-light-red, #fbdbdb);
}

.block-color1 .icon-bg {
  background: var(--pastel-dark-blue, #007aff);
}

.block-color2 .icon-bg {
  background: var(--pastel-dark-orange, #ff9600);
}

.block-color3 .icon-bg {
  background: var(--pastel-dark-green, #28ac47);
}

.block-color4 .icon-bg {
  background: var(--pastel-dark-red, #ed4949);
}

.blockp {
  color: var(--color-secondary-dark-light, #505564);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: -9px !important;
}

.chart-space {
  position: absolute;
  top: 22px;
  right: 9px;
  width: 50%;
  height: 50px;
}

.dashboard {
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f2f4f9;
}

.default-dashboard {
  background: #f2f4f9;
  height: 100vh;
  padding-top: 10px;
}

.dashboard-height {
  height: 100vh;
  overflow: auto;
}

.mutual_blocks {
  border-radius: 10px;
  background: var(--color-white-pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 10px;
  /* min-height: 88vh; */
  height: 100%;
}

.table-responsive {
  min-height: 57vh;
}

.tick-label {
  color: #b4b9c8;
  text-align: center;
  font-family: Oxygen, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.heading_inline {
  justify-content: space-between;
  margin-bottom: 15px;
}

.left_head h2 {
  color: var(--color-secondary-dark-mid, #3c4150);
  font-size: 20px;
  font-weight: 700;
}

.details-link {
  color: var(--color-tertiary-grey, #646978);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

.mean_median {
  justify-content: end;
  gap: 20px;
  margin-bottom: 1px;
  margin-top: 30px;
}

.left_mean,
.right_mean {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.left_mean span {
  background: #22a9e0;
  border: 0px solid #22a9e0;
  padding: 0px 19px;
  font-size: 3px !important;
  margin-right: 10px;
  margin-top: -5px;
  position: relative;
  top: -4px;
}

.right_mean span {
  background: #007aff;
  border: 0px solid #007aff;
  padding: 0px 19px;
  font-size: 3px !important;
  margin-right: 10px;
  margin-top: -5px;
  position: relative;
  top: -4px;
}

.teammate {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #b4b9c8;
  padding-bottom: 23px;
}

.teammate-img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.teammate-info {
  margin-left: 12px;
}

.teammate-info h3 {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 17px;
  font-family: "Oxygen", sans-serif !important;
}

.mater .teammate-info h3 {
  margin-bottom: -2px;
  margin-top: 2px;
  white-space: pre-line;
}

.zero_dep h3 {
  word-break: break-word;
  max-width: 150px;
}

.mater .teammate {
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.mater .heading_inline {
  position: sticky;
  top: -20px;
  background: #fff;
  padding-top: 0px;
  padding-bottom: 10px;
  margin-bottom: 0px !important;
}

.sales-link {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

.team_mamet {
  padding: 20px;
  height: 589px;
  overflow: auto;
}

.team_mamet::-webkit-scrollbar {
  height: 10px;
  width: 2px;
}

.team_mamet::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.team_mamet::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  height: 10px !important;
}

.team_mamet::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.heading_inline {
  margin-bottom: 30px;
}

.icon_mut {
  font-size: 22px !important;
  position: relative;
  top: 2px;
}

.icon_text_area {
  margin-top: 28px;
}

.borrom_blocks h3 {
  color: var(--color-tertiary-grey-mid, #787d8c);
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
}

.borrom_blocks p {
  color: var(--color-tertiary-grey, #646978);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.top_set {
  margin-top: -12px;
}

.text_set {
  color: var(--color-tertiary-grey, #646978);
  font-size: 14px !important;
  font-weight: 400;
  line-height: 150%;
  margin-left: 10px;
}

.iconing {
  margin-top: 15px;
}

.header {
  padding-right: 20px;
}

.single_social a {
  border-radius: 4px;
  border: 1px solid var(--color-secondary-dark-light, #505564);
  background: var(--color-secondary-dark-light, #505564);
  height: 40px;
  width: 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 6px;
  display: inline-block;
}

.share_links.d-flex {
  gap: 8px;
}

.social_area p {
  color: var(--color-tertiary-grey, #646978);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.followme {
  margin-top: 24px;
}

.followme h4 {
  color: var(--color-tertiary-grey-mid, #787d8c);
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
}

.teammate:last-child {
  border-bottom: none;
}

.single_social a {
  color: #fff !important;
}

.header-dashboard.main_container {
  background: #f7f8f9;
}

.my_top {
  margin-top: -10px !important;
}

.row.hatters {
  margin-top: 0px;
}

.col-lg-2.col-sm-12.wid_set.mater {
  padding-left: 0px !important;
}

.col-lg-6.col-md-12.col-sm-12.passder {
  padding-right: 0px;
}

.halop {
  font-size: 16px !important;
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .wid_adjust {
    width: 78% !important;
  }

  .wid_set {
    width: 22% !important;
  }

  .blockp {
    font-size: 15px;
  }

  .number {
    font-size: 22px;
  }

  .teammate-info h3 {
    font-size: 15px;
  }

  .borrom_blocks p {
    font-size: 15px;
  }

  .social_area p {
    font-size: 15px;
  }

  .option-icon {
    font-size: 20px;
  }

  a.option-link {
    font-size: 14.5px !important;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  a.option-link {
    font-size: 14.5px !important;
  }

  .wid_adjust {
    width: 78% !important;
  }

  .wid_set {
    width: 22% !important;
  }

  .blockp {
    font-size: 15px;
  }

  .number {
    font-size: 22px;
  }

  .teammate-info h3 {
    font-size: 15px;
  }

  .borrom_blocks p {
    font-size: 15px;
  }

  .social_area p {
    font-size: 15px;
  }

  .option-icon {
    font-size: 20px;
  }
}

@media (min-width: 1306px) and (max-width: 1920px) {
  .row.abc {
    width: 101% !important;
  }
}

@media (min-width: 1280px) and (max-width: 1305px) {
  .teammatess {
    width: 32.6% !important;
  }

  .row.abc {
    width: 101.5% !important;
  }

  .team_mamet {
    height: 580px;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .chart-space {
    position: absolute;
    top: 14px;
    right: 16px;
  }

  .number {
    font-size: 20px !important;
  }

  .icon-bg {
    font-size: 22px !important;
  }

  .wid_adjust {
    width: 76% !important;
  }

  .wid_set {
    width: 24% !important;
  }

  .blockp {
    font-size: 15px;
  }

  .number {
    font-size: 22px;
  }

  .teammate-info h3 {
    font-size: 15px;
  }

  .borrom_blocks p {
    font-size: 15px;
  }

  .social_area p {
    font-size: 15px;
  }

  a.option-link {
    font-size: 14.5px !important;
  }
}

/* Mobile styles */
.mobile_only {
  display: none;
}

.toggle-button {
  display: none;
}

@media (min-width: 992px) and (max-width: 1279px) {
  .wid_adjust {
    width: 100% !important;
  }

  .wid_set {
    width: 100% !important;
    margin-top: 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .header {
    padding-right: 0px;
  }

  .row.abc {
    width: 101.5%;
  }

  .team_mamet {
    height: 419px;
    overflow: auto;
  }

  .col-lg-2.col-sm-12.wid_set.mater {
    padding-left: 10px !important;
    margin-top: 0px !important;
  }

  .row.hatters {
    margin-top: 10px;
  }

  /*  .sidebar {
    width: 169px !important;
  } */
  .middle_sidebar {
    width: 87% !important;
    margin-left: 22px !important;
  }

  .number {
    font-size: 19px !important;
  }

  .blockp {
    font-size: 14px !important;
  }

  .borrom_blocks {
    margin-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .hatters .passder {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mutual_blocks {
    padding: 13px;
    margin-top: 10px;
  }

  .row.hatters {
    margin-top: -10px;
  }

  .col-lg-6.col-md-12.col-sm-12.passder {
    padding-right: 10px;
  }

  .mater .heading_inline {
    top: -13px;
  }

  .col-lg-2.col-sm-12.wid_set.mater {
    padding-left: 10px !important;
  }

  .team_mamet {
    height: 379px;
    overflow: auto;
  }

  .my_top {
    margin-top: 0px !important;
  }

  .halowon {
    padding-right: 10px !important;
  }

  .wid_adjust .col-lg-3 {
    width: 49%;
  }

  .right-section {
    width: 100%;
    /*  margin-top: 20px; */
    justify-content: flex-end;
  }

  .left-section p {
    font-size: 12px;
  }

  .mobile_only {
    display: block;
  }

  .sidebar {
    display: none;
  }

  .sidebar.visible {
    display: block;
  }

  .toggle-button {
    display: inline-block;
    /* your styling for button */
  }

  button.toggle-button {
    position: absolute;
    top: 16px;
    left: 11px;
  }

  button.collapse-btn.btn-custom {
    display: none;
  }

  .dashboard {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-section {
    width: 100%;
    /* margin-top: 20px; */
  }

  .left-section {
    gap: 7px;
    width: 100%;
    margin-top: -9px;
    justify-content: flex-end;
  }

  .header {
    flex-wrap: wrap;
  }

  .icon-bg {
    padding: 8px;
    top: 14px;
    left: 10px;
    font-size: 19px;
  }

  .number {
    font-size: 18px;
  }

  .blockp {
    font-size: 14px;
  }

  .block {
    margin-bottom: 9px;
  }

  .left_head h2 {
    font-size: 17px;
  }

  .left_mean,
  .right_mean {
    font-size: 11px;
  }

  .mutual_blocks {
    padding: 13px;
  }

  .left_mean span {
    padding: 0px 6px;
  }

  .right_mean span {
    padding: 0px 6px;
  }

  .team_mamet {
    margin-top: 20px;
  }

  .mutual_blocks {
    padding: 13px;
    margin-top: 20px;
  }

  .borrom_blocks p,
  .social_area p {
    font-size: 14px;
  }

  .single_social a {
    height: 35px;
    width: 35px;
    font-size: 15px;
  }

  .search-box input {
    width: 100%;
  }

  .header {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.halowon {
  padding-right: 0px !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .hatters .passder {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mutual_blocks {
    padding: 13px;
    margin-top: 10px;
  }

  .row.hatters {
    margin-top: -10px;
  }

  .col-lg-6.col-md-12.col-sm-12.passder {
    padding-right: 10px;
  }

  .mater .heading_inline {
    top: -13px;
  }

  .col-lg-2.col-sm-12.wid_set.mater {
    padding-left: 10px !important;
  }

  .team_mamet {
    height: 379px;
    overflow: auto;
  }

  .my_top {
    margin-top: 0px !important;
  }

  .halowon {
    padding-right: 10px !important;
  }

  .right-section {
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
  }

  .search-box input {
    width: 158px;
  }

  .header {
    padding-right: 0px;
    padding-left: 13px;
  }

  .left-section p {
    font-size: 12px;
  }

  .mobile_only {
    display: block;
  }

  .sidebar {
    display: none;
  }

  .sidebar.visible {
    display: block;
  }

  .toggle-button {
    display: inline-block;
    /* your styling for button */
  }

  button.toggle-button {
    position: absolute;
    top: 16px;
    left: 11px;
  }

  button.collapse-btn.btn-custom {
    display: none;
  }

  .dashboard {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-section {
    width: 100%;
    margin-top: 0px;
  }

  .left-section {
    gap: 7px;
    width: 100%;
    margin-top: -9px;
    justify-content: flex-end;
  }

  .header {
    flex-wrap: wrap;
  }

  .icon-bg {
    padding: 8px;
    top: 14px;
    left: 10px;
    font-size: 19px;
  }

  .number {
    font-size: 18px;
  }

  .blockp {
    font-size: 14px;
  }

  .block {
    margin-bottom: 9px;
  }

  .left_head h2 {
    font-size: 17px;
  }

  .left_mean,
  .right_mean {
    font-size: 11px;
  }

  .mutual_blocks {
    padding: 13px;
  }

  .left_mean span {
    padding: 0px 6px;
  }

  .right_mean span {
    padding: 0px 6px;
  }

  .team_mamet {
  }

  .mutual_blocks {
    padding: 13px;
  }

  .borrom_blocks p,
  .social_area p {
    font-size: 14px;
  }

  .single_social a {
    height: 35px;
    width: 35px;
    font-size: 15px;
  }
}

.main-logo {
  text-align: center;
}

.sidebar-main {
  position: relative;
}

/* Sidebar.css */
