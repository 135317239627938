.input-small{
    margin-left: 108px;width: 50% !important;
}



    .google_signin, .cus_logger{ min-width: 48% !important;}

    .two_log_btns {
        justify-content: space-between;
        margin-left: 14px;
    }


    .google_signin button{ background:none !important; border: 1px solid #4081ec !important; color: #4081ec !important;}

    .google_signin button img{ width: 18px; margin-right: 5px;}



    .google_signin button{ 
        border-radius: 4px !important;
       
        box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
        color: #4081ec !important;
        text-align: center !important;
        font-size: 16px !important;
        font-weight: 500 !important;
       
        
        padding: 12px 8px !important;}

   .logger_all button{  border-radius: 4px !important;
    background: var(--color-primary-green, #80C342) !important;
    box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
    color: var(--color-white-pure, #FFF) !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border: none !important; width: 100%;
    
    padding: 12px 8px !important;}

    .loggers { flex-wrap: wrap; justify-content: space-between !important; margin-left: 10px; margin-top: 30px;}

    .logger_all {
        width: 49%;
    }
    .loggert_two { width: 49%;}

    .loggert_two button{ width: 100% !important;}


    /* packages */

.billing_heading{ font-size: 18px; font-family: 'Oxygen', sans-serif !important; color: #646978; }
.billing_date{ font-size: 22px; font-weight: bold; color: #646978; font-family: 'Roboto', sans-serif !important;}

.second_billing{ display: flex; margin-left: 20px;}

.both_billing{ flex-wrap: wrap; justify-content: center;}

.inner_mutual_block{ padding: 30px;     border-radius: 10px;
    background: var(--color-white-pure, #FFF);
    box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.10);
   
   }

   .happing{ padding: 30px !important;}
   .inner_mutual_block h3{ margin: 0px; padding: 0px;}

   /* card */

   /* payment-css */




.payment_area .form-control{ border-radius: 5px;
    border: 1px solid #D9D9D9; height: 48px;}


    .two_sectors{ flex-wrap: wrap; margin-top: 10px; justify-content: space-between; align-items: center;}

    .sector_one{ width: 48%;}

    .sector_two{ width: 48%;}

    .payment_area label{ color: #3C4150;
         font-family: 'Roboto', sans-serif !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;}

        .payment_area p{ color: #787D8C;
            font-family: 'Oxygen', sans-serif !important;             font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; margin-top: 7px;}


.payment_area{ flex-wrap: wrap; justify-content: space-between; margin-top: 50px; margin-bottom: 50px; }

.left_payment_area {
    width: 67%;
    margin-top: 30px; position: relative; 
}
.right_payment_area{ width: 30%;}


.field_one{ position: relative;}

.card_logo {
    position: absolute;
    top: 73px;
    left: 6px;
}

.second_logo {
    position: absolute;
    right: 12px;
    top: 74px;
}

.field_one .form-control {
    padding-left: 50px;
    padding-right: 20px; color: #3D3D3D;
     font-family: 'Roboto', sans-serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.two_sectors .form-control{ 
     font-family: 'Roboto', sans-serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; text-align: center;}


    .two_sectors .form-control::placeholder { 
        color: #D9D9D9; 
        opacity: 1; 
      }


    .sector_set span {
        margin-left: 20px;
        margin-right: 20px; color: #3D3D3D;
         font-family: 'Roboto', sans-serif !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; margin-top: 10px;
    }


    .sector_set .form-control{ color: #3D3D3D;
         font-family: 'Roboto', sans-serif !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;}

        .pps{ border-radius: 50px;
            background: var(--Gradient, linear-gradient(156deg, #1E336A 41.1%, #00BBF2 123.04%)); color: #FFF;
             font-family: 'Roboto', sans-serif !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; padding: 11px 100px; text-align: center; border: none;}

            .payment_btnew {
                text-align: center;
                margin-top: 50px;
            }

          /*   .right_payment_area{ border-radius: 10px;
                background: #FAFAFA; padding: 24px;}
 */




                .right_payment_area .card_detail {
                    position: relative;
                   
                  }
                  
                  .right_payment_area .card_number {
                    position: absolute;
                    top: 54%;
                    left: 10%;
                    color: white;
                    font-size: 30px;
                    font-weight: 600;
                    font-family: 'Roboto', sans-serif !important;
                }
                  
                  .right_payment_area .card_expiry {
                    position: absolute;
                    bottom: 20%;
                    left: 10%;
                    color: white;
                    font-size: 22px;
                }
                  
                  .right_payment_area .card_name {
                    position: absolute;
                    bottom: 7%;
                    left: 10%;
                    color: white;
                    font-size: 24px; font-weight: bold;
                    
                  }


                  .card_name{  font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;  font-family: 'Roboto', sans-serif !important;}


                    .card_expiry{  font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;  font-family: 'Roboto', sans-serif !important;}


                        .card_expiry span{ color: #fff;
                            font-family: 'Oxygen', sans-serif !important; 
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;}


                            .package_btn button{ border-radius: 20px;
                                border: 1px solid #1E336A; color: #1E336A;
                                 font-family: 'Roboto', sans-serif !important;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal; padding: 6px 60px; background: none; margin-top: 20px;}


                                .package_title{ color: #8B8B8B;
                                     font-family: 'Roboto', sans-serif !important;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal; margin-top: 20px;}


                                    .package_btn{ text-align: center;}

                                    .need_help{ color: #8B8B8B;
                                        text-align: center;
                                         font-family: 'Roboto', sans-serif !important;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;}

                                        .need_help{ margin-top: 30px;}
                                        
                                        .border_dashed {
                                            margin-left: -23px;
                                            margin-top: 23px;
                                        }

                                        .trow_one{ justify-content: space-between;}

                                        .second_trow{ margin-top: 10px;}

                                        .total_row{ margin-top: 25px;}


                                        .left_t_row{ color: #8B8B8B;
                                             font-family: 'Roboto', sans-serif !important;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: normal;}
                                        .right_t_row{ color: #3D3D3D;
                                            text-align: right;
                                             font-family: 'Roboto', sans-serif !important;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: normal;}

                                            .big_fonts{ font-size: 24px !important; }

#cardCVC{ text-align: center;}

.billing-cycle {
    font-family: 'Roboto', sans-serif !important;
    color: #000;
    display: flex;
    align-items: center; justify-content: center; margin-top: 30px;
  }

.billing-cycle-label{ font-size: 22px; font-weight: bold;  font-family: 'Roboto', sans-serif !important; color: #646978;}

  
  .billing-cycle-label {
    margin-right: 20px;
  }
  
  .radio-options {
    display: flex;
  }
  
  .custom-radio {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .custom-radio input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #22A9E0;
    border-radius: 50%;
    outline: none;
    margin-right: 10px;
    position: relative;
  }
  
  .custom-radio input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background: #22A9E0;
    border-radius: 50%;
  }
  
  .radio-text {
    color: #646978;font-family: 'Oxygen', sans-serif !important; 
  }
  
  .monthly:before,
  .annual:before {
  }
  
  
  .bottom_tft{ text-align: center; margin-top: 10px; color: #646978; font-family: 'Oxygen', sans-serif !important; }

  .add-new-card-container {
    display: flex;
    align-items: center;
  }
  
 .shelter .custom-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    color: #ffffff;
  }
  
  .shelter .custom-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  
  .shelter .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    
    border-radius: 4px;
  }
  
  .shelter:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
 .shelter input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .shelter .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .shelter input:checked ~ .checkmark:after {
    display: block;
  }
  
  .shelter .shelter .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
  
.shelter .checkbox-label {
    margin-left: -5px;
    font-size: 14px;
    color: #646978;
    font-weight: 500;
    font-family: 'Oxygen', sans-serif !important;
    position: relative;
    top: -4px;
}
  
  /* Unique classes for custom styling */
  .unique-checkbox{}

  .last_blocks{ background: #F7F8F9; width: 50%; justify-content: center; align-items: center; margin-left: 25%; padding: 20px; gap: 20px;}
  .cancelling_pck {
    color: #22A9E0;
    border: 1px solid #22A9E0;
    border-radius: 5px;
    text-decoration: none;
    padding: 8px 20px; font-size: 15px; font-weight: 500;
}
  

.haloss{ background: #22A9E0 !important; text-decoration: none; border-radius: 4px !important; padding: 11px 20px !important; font-size: 15px !important; font-weight: 400 !important; color: #fff !important;}

.payment_btnew button{ background: transparent ; text-decoration: none; border-radius: 4px; padding: 11px 44px; font-size: 15px; font-weight: 400; margin-top: -10px;  color: #22A9E0;
    border: 1px solid #22A9E0;}

.add_cancel{ gap: 20px;}
.left_arrowa img{ rotate: 180deg;}
.slide_counting{ font-size: 16px; font-weight: bold; font-family: 'Roboto', sans-serif !important; color: #22A9E0;}
.card_slides {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
                 
.slide_counting {
    letter-spacing: 5px;
    margin-top: 2px;
}

.faded{ opacity: 0.5;}


.shelter label.custom-checkbox-container {
  
    position: absolute;
    right: 43px;
    margin-top: 120px; z-index: 1;
}
.left_arrowa{ cursor: pointer;}
.right_arrowa{ cursor: pointer;}
.caro-left_right {
    justify-content: space-between;
  
   
    
}

.ddl {
    
    width: 30%;
    position: absolute;
    top: 370px;
}

.edit_del{ gap: 20px;}

.maker_card{ position: static ;}


.maker_card label.custom-checkbox-container {
   
    left: 0px !important; margin-top: 70px !important;
   
}
.default_card{ margin-bottom: 50px !important;}

.delete_slide{ cursor: pointer;}
.peppn .carousel-item{ padding-bottom: 10px !important;}



@media screen and (min-width:455px) and (max-width:992px) {

    .billing-cycle {
        flex-wrap: wrap !important;
    }
    .billing-cycle-label {
        font-size: 19px !important;
        margin-bottom: 15px !important;
    }
    .radio-options {
        display: flex !important;
        flex-wrap: wrap !important;
        font-size: 15px !important;
    }
}

@media screen and (min-width:1156px) and (max-width:1279px) { .ddl{ top: 400px; width: 42% !important;}}

@media screen and (min-width:1025px) and (max-width:1155px) { .ddl{ top: 350px; width: 42% !important;}}

@media screen and (min-width:1025px) and (max-width:1279px) {

    .ddl{ position: absolute !important;}
    .left_payment_area {
        margin-top: 10px !important;
    }
    .right_payment_area {
      
        margin-top: 50px !important;
    }
    .right_payment_area .card_number {
        font-size: 18px !important;
        top: 55%;
    }
    .card_expiry span {
       
        font-size: 16px !important;
       
    }
    .right_payment_area .card_name{ font-size: 18px !important;}

}


@media screen and (min-width:320px) and (max-width:1024px) {

    .ddl{ position: static !important;}
    .left_payment_area {
        margin-top: 10px !important;
    }
    .right_payment_area {
      
        margin-top: 50px !important;
    }

}




@media screen and (min-width:1280px) and (max-width: 1399px) {

    .right_payment_area .card_number{ font-size: 21px;}

    .left_payment_area {
        width: 60%;
    }
    .right_payment_area {
        width: 35%;
    }



}                                          
                                            
 @media screen and (min-width:1025px) and (max-width: 1279px) {

    .right_payment_area .card_number {
        font-size: 22px;     top: 55%;
    }


    .right_payment_area .card_expiry {
        position: absolute;
        bottom: 20%;
        left: 10%;
        color: white;
        font-size: 13px;
    }
    
    .right_payment_area {
       
        padding: 12px;
    }

    .border_dashed svg {
        width: 109%;
    }


    .left_payment_area {
        width: 52%;
    }
    .right_payment_area {
        width: 44%;
    }
 



 }


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .right_payment_area .card_expiry {
        position: absolute;
        bottom: 20%;
        left: 10%;
        color: white;
        font-size: 13px;
    }
    .right_payment_area .card_number{ font-size: 18px;}
    .right_payment_area {
       
        padding: 12px;
    }

    .border_dashed svg {
        width: 109%;
    }


    .left_payment_area {
        width: 52%;
    }
    .right_payment_area {
        width: 44%;
    }
    .sector_one {
        width: 100%;
    }
    .sector_two {
        width: 100%;
    }

}




                                            @media screen and (min-width: 481px) and (max-width: 1024px) {

                                                .payment_area {
                                                    
                                                    margin-top: 30px;
                                                    margin-bottom: 30px;
                                                }
                                            
                                                .left_payment_area {
                                                    width: 100%;
                                                }
                                                .right_payment_area{ width: 100%;}
                                            
                                                .payment_area label {
                                                   
                                                    font-size: 14px;
                                                    
                                                }
                                                .sector_one {
                                                    width: 100%;
                                                }
                                                .sector_two {
                                                    width: 100%;
                                                }
                                                .right_payment_area .card_number {
                                                    font-size: 28px;
                                                }
                                            
                                                .card_expiry span {
                                                   
                                                    font-size: 11px;
                                                   
                                                }
                                                .right_payment_area .card_expiry {
                                                   
                                                    font-size: 20px;
                                                }
                                            
                                                .right_payment_area .card_name {
                                                  
                                                    font-size: 18px;
                                                }
                                            
                                               .border_dashed svg {
                                                    width: 109%;
                                                }
                                            
                                            
                                            
                                            
                                            
                                            
                                            
                                                                                        }


@media screen and (min-width: 320px) and (max-width: 480px) {

    .payment_area {
        
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .left_payment_area {
        width: 100%;
    }
    .right_payment_area{ width: 100%;}

    .payment_area label {
       
        font-size: 14px;
        
    }
    .sector_one {
        width: 100%;
    }
    .sector_two {
        width: 100%;
    }
    .right_payment_area .card_number{ font-size: 17px;}

    .card_expiry span {
       
        font-size: 11px;
       
    }
    .right_payment_area .card_expiry {
       
        font-size: 12px;
    }

    .right_payment_area .card_name {
      
        font-size: 13px;
    }

   .border_dashed svg {
        width: 109%;
    }







                                            }
             






    @media (min-width: 993px) and (max-width:1399px) {


        .hills{margin-left: 6.666667% !important;
            width: 44% !important;}

    }



    @media (min-width: 320px) and (max-width:480px) {

        .logger_all {
            width: 100%;
        }
        .loggert_two {
            width:100%; margin-top: 30px;
        }

    }

    



    /* package-payment-page */


    @media screen and (min-width: 320px) and (max-width: 480px) {

        .happing {
            padding: 10px !important;
        }
        .happing .inner_mutual_block h3{ font-size: 20px !important;}
        .inner_mutual_block{ padding: 10px;}

    }






    @media screen and (min-width:1601px) and (max-width:1800px) {
        .left_payment_area {
            width: 61%;
           
        }
        .right_payment_area {
            width: 35%;
        }

        .left_payment_area {
           
            margin-top: 10px;
           
        }
        .right_payment_area .card_number {
            font-size: 19px !important;
        }
        .card_expiry span {
         
            font-size: 18px;
           
        }
        .right_payment_area .card_expiry {
           
            font-size: 16px;
        }
        .right_payment_area .card_name {
           
            font-size: 19px;
            
        }
        .ddl {
            width: 35%;
            position: absolute;
            top: 390px;
        }
    }






    @media screen and (min-width:1501px) and (max-width:1600px) {
        .left_payment_area {
            width: 61%;
           
        }
        .right_payment_area {
            width: 35%;
        }

        .left_payment_area {
           
            margin-top: 10px;
           
        }
        .right_payment_area .card_number {
            font-size: 19px !important;
        }
        .card_expiry span {
         
            font-size: 18px;
           
        }
        .right_payment_area .card_expiry {
           
            font-size: 16px;
        }
        .right_payment_area .card_name {
           
            font-size: 19px;
            
        }
        .ddl {
            width: 35%;
            position: absolute;
            top: 350px;
        }
    }




    @media screen and (min-width:1434px) and (max-width:1500px) {
        .left_payment_area {
            width: 61%;
           
        }
        .right_payment_area {
            width: 35%;
        }

        .left_payment_area {
           
            margin-top: 10px;
           
        }
        .right_payment_area .card_number {
            font-size: 19px !important;
        }
        .card_expiry span {
         
            font-size: 18px;
           
        }
        .right_payment_area .card_expiry {
           
            font-size: 16px;
        }
        .right_payment_area .card_name {
           
            font-size: 19px;
            
        }
        .ddl {
            width: 35%;
            position: absolute;
            top: 330px;
        }
    }




    @media screen and (min-width:1400px) and (max-width: 1433px) {
        .left_payment_area {
            width: 61%;
           
        }
        .right_payment_area {
            width: 35%;
        }

        .left_payment_area {
           
            margin-top: 10px;
           
        }
        .right_payment_area .card_number {
            font-size: 19px !important;
        }
        .card_expiry span {
         
            font-size: 18px;
           
        }
        .right_payment_area .card_expiry {
           
            font-size: 16px;
        }
        .right_payment_area .card_name {
           
            font-size: 19px;
            
        }
        .ddl {
            width: 35%;
            position: absolute;
            top: 310px;
        }
    }
    
    @media screen and (min-width: 1280px) and (max-width: 1399px) {

        .left_payment_area {
           
            margin-top: 10px;
           
        }
        .right_payment_area .card_number {
            font-size: 19px !important;
        }
        .card_expiry span {
         
            font-size: 18px;
           
        }
        .right_payment_area .card_expiry {
           
            font-size: 16px;
        }
        .right_payment_area .card_name {
           
            font-size: 19px;
            
        }
        .ddl {
            width: 35%;
            position: absolute;
            top: 300px;
        }
    }





    @media screen and (min-width: 320px) and (max-width: 480px) {
        .both_billing {
            
            margin-top: 10px;
        } 
        .billing_date {
            font-size: 16px;
           
        }
        .billing_heading {
            font-size: 15px;
           
        }
        .billing-cycle {
            
            flex-wrap: wrap;
        }
        .radio-options {
            display: flex;
            flex-wrap: wrap;
            font-size: 15px;
        }

        .billing-cycle-label {
            font-size: 19px;
           
            margin-bottom: 15px;
        }
        .custom-radio {
            display: flex;
            align-items: flex-start;
            margin-right: 20px;
            gap: 0px;
        }
        .annual{ margin-top: 10px;}
        .bottom_tft{ font-size: 14px;}
        .card_detail {
            margin-top: 50px;
        }
        .ddl {
            width: 100%;
            position: absolute;
            top: 276px;
            margin-bottom: 50px;
        }
        .left_payment_area {
           
            margin-top: 100px;
           
        }
        .shelter .checkbox-label {
           
            top: -1px;
        }
        .add_cancel {
            gap: 3px;
        }
    
    }


    