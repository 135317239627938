.teammatess {
  width: 32.7%;
  border-radius: 5px;
  border: 1px solid var(--color-white-blue, #f0f5ff);
  padding: 35px;
  display: flex;
}

.team-mate-page-only {
  flex-wrap: wrap;
}

.team-mate-page-only {
  flex-wrap: wrap;
  justify-content: left;
  gap: 10px;
}
.slu {
  margin-top: 20px;
  margin-right: 0px;
}
.single_icons {
  /* padding: 5px 12px !important; */
}

.ticking {
  width: 80px;
  height: 80px;
}

.team-mate-page-only .teammate-info h3 {
  color: var(--color-tertiary-grey-mid, #787d8c);
  font-family: Oxygen;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.team-mate-page-only .sales-link {
  color: var(--color-tertiary-grey-light, #b4b9c8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  text-decoration-line: underline;
}

.team-mate-page-only .teammate-img {
  height: 80px;
  width: 80px;
}

.team-matew-page {
  /* margin-top: 20px; */
  margin-bottom: -10px;
}

.team-matew-page h2 {
  color: var(--color-secondary-dark-mid, #3c4150);

  font-size: 24px;

  font-weight: 700;
}

.team-matew-page .right_head {
  color: var(--color-tertiary-grey-mid, #787d8c);

  font-family: Roboto;
  font-size: 16px;

  font-weight: 700;
}

.custom-pagination {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.custom-pagination ul {
  margin: 0px;
  padding: 0px;
}

.custom-pagination ul li {
  display: inline;
  color: var(--color-tertiary-grey-light, #b4b9c8);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  padding: 0px 25px;
  cursor: pointer;
}

.rotate_img {
  rotate: 180deg;
}

.active-page {
  color: #fff;
  border-radius: 41px;
  background: var(--color-secondary-dark, #141928);
  height: 32px;
  padding: 10px 22px !important;
}

.results_realtor {
  margin-top: 50px;
}

.realtor_right .update_btn {
  padding: 12px 30px !important;
}

.real_left,
.real_right {
  width: 48% !important;
}

.real_row {
  justify-content: space-between;
  flex-wrap: wrap;
}

.realtor_modal .modal-body {
  padding: 32px !important;
  padding-top: 20px !important;
}

.real_row {
  margin-bottom: 10px;
}

.real_full_wifth {
  margin-top: 20px;
}

.raltor_btns .raltor_btnss {
  padding: 13px 65px !important;
  width: 50%;
}

.add_realtor_message {
  text-align: center;
  border: none;
  padding-bottom: 0px;
  border-radius: 5px;
  position: relative;
}

.cross_btn {
  position: absolute;
  right: -40px;
  top: -40px;
  cursor: pointer;
}

.add_realtor_message h3::after {
  position: absolute;
  content: "";
  background: #141928;
  height: 2px;
  width: 170px;
  top: 30px;
  left: 101px;
  text-align: center;
  margin: 0 auto;
}

.add_realtor_message h4 {
  font-family: "Oxygen", sans-serif !important;
  color: var(--color-tertiary-grey-mid, #787d8c);
  text-align: center;

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.two_icons {
  gap: 10px;
}

.teammatess .teammate-info {
  padding-left: 10px;
}

.hidden {
  opacity: 0.5;
}

.teammatess .single_icons img[alt="toggle visibility"] {
  /* Styles for the eye icon when visible */
}

.teammatess .hidden .single_icons img[alt="toggle visibility"] {
  /* Styles for the eye icon when hidden, for example, a line over it */
  position: relative;
}

.teammatess .hidden .single_icons img[alt="toggle visibility"]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: black;
  /* Or any color you want for the line */
  transform: translateY(-50%);
}

.add_multiple_realt {
  color: var(--color-primary-blue, #22a9e0) !important;
  font-family: "Oxygen", sans-serif !important;
  font-size: 14px;

  font-weight: 700;
  line-height: 150%;
  text-decoration-line: underline;
  margin-top: 10px;
  border: none;
  background: none;
}

.slides_arrows {
  display: flex;
  justify-content: space-between;
}

.slider_serting {
  position: relative;
}

.next_aro img {
  transform: rotate(180deg);
  cursor: pointer;
}

.previous_aro {
  position: absolute;
  top: 40%;
  left: -48px;
  cursor: pointer;
}

.next_aro {
  position: absolute;
  top: 40%;
  right: -48px;
  cursor: pointer;
}

.team-mate-page-only .teammatess a {
  margin-top: -8px;
}

.dot_list {
  height: 8px;
  width: 8px;
  background: #b4b9c8;
  border-radius: 500px;
}

.dot_list.active {
  background: #22a9e0;
}

.dotting {
  width: 100%;
  margin-bottom: 10px;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.slides {
  display: none;
}

.slides.active {
  display: block;
}

.slades {
  overflow: hidden;
  position: relative;
}

.slides-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  /* Adjust timing as needed */
}

.slides {
  flex: 0 0 100%;
  /* Each slide takes 100% of the slider width */
  max-width: 100%;
}

.teammate-initials {
  height: 80px;
  width: 80px;
  background: #efefef;
  border-radius: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Oxygen", sans-serif !important;
  color: #919191;
}

.delete_realtor {
  border: 1px solid #dc3545 !important;
  margin-left: 15px;
}

.raltor_btns {
  display: flex;
  justify-content: center;
}

.raltor_btns .raltor_btnss {
  padding: 13px 40px !important;
  width: 37%;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .teammatess {
    width: 31.8% !important;
  }

  .team-mate-page-only {
    gap: 20px !important;
  }

  .right-section {
    padding-right: 15px !important;
  }

  .teammatess {
    width: 49%;

    padding: 11px;

    margin-top: 15px;
  }

  .heading_inline.d-flex.team-matew-page {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .team-mate-page-only .teammate-info h3 {
    font-size: 16px;
  }

  .custom-pagination ul li {
    font-size: 14px;

    padding: 0px 12px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .team-matew-page .right_head {
    font-size: 14px;
  }

  .teammatess {
    width: 100%;
    padding: 17px 7px;
    margin-top: 14px;
  }

  .team-mate-page-only {
    gap: 0px;
  }

  .heading_inline.d-flex.team-matew-page {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .team-mate-page-only .teammate-info h3 {
    font-size: 16px;
  }

  .custom-pagination ul li {
    font-size: 14px;

    padding: 0px 12px;
  }

  .teammate-info h2 {
    font-size: 12px;
  }

  .team-mate-page-only .teammate-info h3 {
    margin-top: 7px !important;
  }
}

@media (min-width: 956px) and (max-width: 1102px) {
  .teammatess {
    width: 31.6% !important;
  }
}

@media (min-width: 768px) and (max-width: 955px) {
  .team-mate-page-only {
    gap: 20px !important;
  }

  .right-section {
    padding-right: 15px !important;
  }

  .teammatess {
    width: 48% !important;
  }

  .realtor_right .update_btn {
    padding: 12px 27px !important;
  }

  .modal-dialog-centered {
    display: inherit !important;
  }

  .delete-modal .modal-content {
    border-radius: 15px;
    width: inherit !important;
  }
}

@media (min-width: 570px) and (max-width: 767px) {
  .team-mate-page-only {
    gap: 20px !important;
  }

  .right-section {
    padding-right: 15px !important;
  }

  .teammatess {
    width: 48% !important;
  }

  .modal-dialog-centered {
    display: inherit !important;
  }

  .delete-modal .modal-content {
    border-radius: 15px;
    width: inherit !important;
  }

  .team-matew-page .right_head {
    font-size: 14px;
    width: 100%;
  }

  .team-matew-page {
    flex-wrap: wrap;
  }

  .d-flex.kala {
    flex-wrap: wrap;
  }

  .realtor_right {
    margin-top: 69px;
  }
}

@media (min-width: 320px) and (max-width: 569px) {
  .team-matew-page h2 {
    margin-top: 7px;
  }

  .team_mamett {
    padding: 10px !important;
    padding-top: 0px !important;
  }
  .modal-dialog-centered {
    display: inherit !important;
  }

  .delete-modal .modal-content {
    border-radius: 15px;
    width: inherit !important;
  }

  .previous_aro {
    left: -40px;
  }

  .next_aro {
    right: -40px;
  }

  .raltor_btns .raltor_btnss {
    padding: 13px 20px !important;
  }

  .team-matew-page .right_head {
    font-size: 14px;
    width: 100%;
  }

  .team-matew-page {
    flex-wrap: wrap;
  }

  .d-flex.kala {
    flex-wrap: wrap;
  }

  .realtor_right {
    margin-top: 69px;
  }
}

/* new-code as per new flow */

.status-actives {
  border-radius: 20px;
  background: #d4eeda;
  padding: 5px 12px;
  color: #28ac47;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-inactives {
  border-radius: 20px;
  background: #cce4ff;
  padding: 5px 12px;
  color: #007aff;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-pendings {
  border-radius: 20px;
  background: #cce4ff;
  padding: 5px 12px;
  color: #007aff;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-pendings {
  border-radius: 20px;
  background: #ffeacc;
  padding: 5px 12px;
  color: #ff9600;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-delinquents {
  border-radius: 20px;
  background: #fbdbdb !important;
  padding: 5px 12px;
  color: #ed4949;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.status-unknowns {
  border-radius: 20px;
  background: #d4eeda;
  padding: 5px 12px;
  color: #28ac47;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  display: inline-block;
}

.add_realtor_message .cross_btn {
  top: -30px;
}

.two_tube_btns {
  justify-content: center;
  gap: 4%;
  margin-top: 50px;
}

.btn_tt_one {
  border-radius: 4px;
  background: #22a9e0;
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  min-height: 47px;
  padding: 13px 35px 13px 35px;
  color: var(--Color-White-Pure, #fff);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
}

.halks_btn {
  border-radius: 4px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Blue, #22a9e0);
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 35px 13px 35px;
  color: var(--Color-White-Pure, #22a9e0);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.btn_tt_one {
  width: 48%;
  cursor: pointer;
}

.halks_btn {
  width: 48%;
  cursor: pointer;
}

.pending_realtor .modal-body {
  border-radius: 10px;
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
  padding: 40px;
}

.pending_realtor .modal-body img {
  height: 80px;
  width: 80px;
  margin-top: 10px;
  margin-bottom: 22px;
}

.pending_realtor h2 {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  text-align: center;

  /* Labels */
  font-family: Oxygen;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pending_realtor p {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.pending_realtor {
  text-align: center;
}

.pending_realtor {
  --bs-modal-width: 420px !important;
}

.email_verification {
  width: 420px !important;
  border: 1px solid #efefef;
  position: absolute;
  top: 10%;
  left: 44%;
  background: #fff;
}

.haroos {
  top: -12px !important;
  right: -8px;
}

.modal-harddrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
}

.email_verification {
  z-index: 9999999999;
}

.emails_verification h3 {
  color: var(--color-secondary-dark, #141928);
  position: relative;
  margin-bottom: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.emails_verification p {
  color: var(--color-tertiary-grey-mid, #787d8c);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.emails_verification img {
  margin: 20px 20px 20px 20px;
}

.emails_verification h4 {
  font-family: "Oxygen", sans-serif !important;
  color: var(--color-tertiary-grey-mid, #787d8c);
  text-align: center;
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.emails_verification h3::after {
  position: absolute;
  content: "";
  background: #141928;
  height: 2px;
  width: 170px;
  top: 30px;
  left: 131px;
  text-align: center;
  margin: 0 auto;
}

.faloos {
  top: -32px !important;
  right: -41px !important;
}

/* new-css */
.realtor_spec_only {
  margin-top: 22px !important;
}

.lisingenumbers {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);

  /* Heading Span */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.csv_upload {
  background: #fff !important;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--Color-Primary-Blue, #22a9e0) !important;
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  color: #22a9e0 !important;
  text-align: center !important;
  font-size: 16px !important;
  padding: 11px 23px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.csv_upload img {
  margin-right: 5px;
}

#datauploading .modal-content,
#uploadCsvModal .modal-content {
  padding: 32px !important;
  border-radius: 10px;
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.1);
}

.csv-input-wrapper {
  margin-bottom: 15px;
}

.csv-upload-input {
  border-radius: 4px;
  border: 1px solid var(--Color-White-Blue, #f0f5ff);
  background: var(--Color-White-Pure, #fff);
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 9px 16px;
  color: var(--Color-Tertiary-Grey-light, #b4b9c8);

  /* Body Small */
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.download-sheet-link {
  color: var(--Color-Primary-Blue, #22a9e0);
  font-family: Oxygen;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 21px */
  text-decoration-line: underline;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 15px;
  text-decoration: underline;
}

.modal-footer {
  border-top: none;
  padding-top: 0;
  justify-content: center;
}

.btn-upload,
.btn-cancel {
  border: none;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 14px;
  margin: 0 10px;
}

.btn-upload {
  background-color: #007bff;
  color: white;
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
}

.btn-upload:hover {
  background-color: darken(#007bff, 10%);
}

.btn-cancel:hover {
  background-color: darken(#6c757d, 10%);
}

#uploadCsvModal .modal-dialog,
#datauploading .modal-dialog,
#datauploaded .modal-dialog {
  --bs-modal-width: 370px !important;
}

.csv_footer {
  border: none !important;
  justify-content: center !important;
}

.csv_footer .btn {
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 40px 13px 40px;
  text-align: center;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.csv_footer .btn-upload {
  background: #22a9e0;
  color: #fff !important;
}

.csv_footer .btn-cancel {
  border: 1px solid var(--Color-Primary-Blue, #22a9e0);
  color: #22a9e0;
}

.csv_footer .btn-upload:hover {
  background: #22a9e0;
}

#datauploading {
  text-align: center;
}

#datauploading h3 {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  text-align: center;

  /* Labels */
  font-family: Oxygen;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#datauploading img {
  width: 84px;
  height: 84px;
  margin-top: 15px;
}

#datauploading .calculation_zone {
  margin-top: 40px;
  margin-bottom: 40px;
}

#datauploading .modal-content {
  padding: 15px !important;
}

#datauploading .slider-container {
  position: relative;
}

#datauploading .thumb {
  height: 0px;
  width: 0px;
  text-align: center;
  background-color: #00aaff;
  color: #00aaff;
  border-radius: 50%;
  cursor: grab;
  top: 0px;
}

.track {
  position: relative;
}

.percentage-box {
  position: absolute;
  top: auto;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 14px;
  border-radius: 5px;
  font-size: 0.75em;
  white-space: nowrap;
}

.percentage-box::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 27px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  transform: translateX(-50%);
}

#datauploaded {
  text-align: center;
}

#datauploaded h3 {
  color: var(--Color-Tertiary-Grey-mid, #787d8c);
  text-align: center;

  /* Labels */
  font-family: Oxygen;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#datauploaded img {
  width: 84px;
  height: 84px;
  margin-top: 15px;
}

#datauploaded .calculation_zone {
  margin-top: 40px;
  margin-bottom: 40px;
}

#datauploaded .modal-content {
  padding: 15px !important;
}

#datauploaded .slider-container {
  position: relative;
}

#datauploaded .thumb {
  height: 0px;
  width: 0px;
  text-align: center;
  background-color: #00aaff;
  color: #00aaff;
  border-radius: 50%;
  cursor: grab;
  top: 0px;
}

.email_verification.verification_done {
  position: static;
  border: none !important;
}

#uploadCsvModal .modal-dialog,
#datauploading .modal-dialog,
#datauploaded .modal-dialog {
  --bs-modal-width: 395px !important;
}

.csv_footer {
  border: none !important;
  justify-content: center !important;
}

.csv_footer .btn {
  border-radius: 4px;

  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.5);
  padding: 13px 40px 13px 40px !important;
  text-align: center !important;

  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px;
}

.csv_footer .btn-upload {
  background: #22a9e0 !important;
  color: #fff !important;
}

.csv_footer .btn-cancel {
  border: 1px solid var(--Color-Primary-Blue, #22a9e0) !important;
  color: #22a9e0 !important;
  background: #ffff !important;
}

.csv_footer .btn-upload:hover {
  background: #22a9e0 !important;
}
